// ==========================================================================/**
//   Base Form Styles
// ==========================================================================/**

/**
 * PLEASE READ ME
 *
 * Includes base resets for the form styling shared across elements
 *
 * Optimisation
 * If you are sharing values e.g. hover and focus colour merge the
 * values so we are not outputting bloated uneccessary css.
 *
*/

$form--fill: $greyNurse;
$form--placeholder: $greyDove;
$form--focus: #eee;
$form--text: #000;
$form--text-focus: #333;

$form--border: #d9d9d9;
$form--border-focus: $blueBiscay;

// Disabled
$form--disabled: repeating-linear-gradient(-45deg, #fff, #fff 5px, $greyNurse 5px, $greyNurse 10px);
$form--disabled-text: darken(#fff, 30);

// error
$form--error-bkg: #ffbebe;
$form--error-border: #900000;
$form--error-text: #900000;

// Toggle Switch
$form--toggle-bg: #fff;

$select-height: 44px; // matches height of inputs;

.#{$prefix}form__field-group {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 0.5em;
	@extend .#{$prefix}clearfix;

	.#{$prefix}form--inline & {
		@include bp(sm) {
			display: inline-block;
			width: auto;
			margin: 0 0 0 2em;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}
@include bp(sm) {
	.#{$prefix}form__btn-group {
		float: right;
	}
}


// Labels
//  ==========================================================================
.#{$prefix}form__label {
	@include font-size(14px);
	font-weight: 700;
	text-transform: uppercase;
	color: $blueDenim;
	display: block;
	margin-bottom: 0.3em;
	margin-top: 1.2em;
	cursor: pointer;
	user-select: none;
	transition: color 300ms ease-in-out;

	.has-focus & {
		color: $blueBiscay;
	}

	.is-disabled & {
		color: $form--disabled-text;
	}
}

.#{$prefix}form__focus-indicator {
	position: absolute;
	right: 100%;
	border-left: 8px solid $blueBiscay;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	margin: 16px 3px;
	opacity: 0;
	transition: opacity 300ms ease-in-out;

	.has-focus & {
		opacity: 1;
	}

	.has-error & {
		border-left-color: $form--error-border;
	}
}

.#{$prefix}form__sans-label {
	padding-top: 2.44em;

	&--xs {
		padding-top: 0.6em;
		@include bp(xs) {
			padding-top: 2.44em;
		}
	}

	&--sm {
		padding-top: 0.6em;
		@include bp(sm) {
			padding-top: 2.44em;
		}
	}

	&--md {
		padding-top: 0.6em;
		@include bp(md) {
			padding-top: 2.44em;
		}
	}
}

.#{$prefix}form__label-only {
	padding: 0 0 0.7em 0;
    text-align: left;

	@include bp(md) {
		padding: 0.7em 0;
		text-align: center;
	}
}

.#{$prefix}form__results-total {
	@include font-size(13px);
	color: $greyDove;
	padding-top: 1.5em;
}

.#{$prefix}form__results-type {
	border-bottom: 1px solid $greyNurse;
	text-transform: uppercase;
	text-decoration: none;
	color: $greyDove;
	padding-bottom: 0.6em;
	margin-bottom: 1.5em;
	display: block;
}

// Ascending and Descending arrows used on results ordering
.#{$prefix}form__results-order {
	color: $greyDove;
	display: inline-block;
	position: relative;
	margin-left: 0.5em;

	.#{$prefix}icon--sort-arrow-ascend,
	.#{$prefix}icon--sort-arrow-descend  {
		color: #c4c5c4;
		font-size: 0.45em;
		position: absolute;
		top: -1.8em;
	}

	.#{$prefix}icon--sort-arrow-descend {
		top: -0.6em;
	}

	.order_desc & .#{$prefix}icon--sort-arrow-descend {
		color: $greyNurse;
	}

	.order_asc & .#{$prefix}icon--sort-arrow-ascend {
		color: $greyNurse;
	}
}