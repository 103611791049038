//-----------------------------------------------------------------
// Fonts and Typography
//-----------------------------------------------------------------

// Calculate REM Size
@function calculateRem($size) {
	$remSize: $size / $baseFontSize;
	@return $remSize * 1rem;
}

// Calculate EM Size
@function calculateEm($size) {
	$emSize: $size / $baseFontSize;
	@return $emSize * 1em;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}
// Example usage - @include font-size(14px)



// MAP FETCH
// Fetches nested sass maps or values
@function map-fetch($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}



//-----------------------------------------------------------------
//Breakpoints
//-----------------------------------------------------------------


// Still needs something to ensure px values
@mixin bp($breakpoint:null, $minormax:min, $widthorheight:width) {

	@if not map-has-key((max:0,min:0), $minormax)  {
		$minormax: min;
	}

	@if not map-has-key((width:0,height:0), $widthorheight)  {
		$widthorheight: width;
	}

  	@if map-has-key(map-fetch($breakpoints, $widthorheight), $breakpoint) {
		$breakpoint: map-fetch($breakpoints, $widthorheight, $breakpoint);
		@if $minormax == max {
			$breakpoint: $breakpoint - 1px;
		}
    }

	@if $breakpoint {
		@media screen and (#{$minormax}-#{$widthorheight}: calculateEm($breakpoint)) { @content; }
	}
}




//-----------------------------------------------------------------
// Handling long words
//-----------------------------------------------------------------
@mixin ellipsis() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin word-wrap() {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}

////
/// @author Mike Street
/// @group Sprite
/// @param {Number} $px - value to convert
/// @param {Number} $base-font-size ($mq-base-font-size) - `<body>` font size
///
/// @example scss
///  $font-size-in-ems: mq-px2em(16px);
///  p { font-size: mq-px2em(16px); }
///
/// @requires $mq-base-font-size
/// @returns {Number}
$mq-base-font-size: $baseFontSize;

@function mq-px2em($px, $base-font-size: $mq-base-font-size) {
	@if unitless($px) {
		@warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";
		@return mq-px2em($px + 0px); // That may fail.
	} @else if unit($px) == em {
		@return $px;
	}
	@return ($px / $base-font-size) * 1em;
}

/// Add class for <IE9 sprite fallback
$ieSprite: '.lt-ie9' !default;

/// Set the `$sprite` map
$sprite: map-get($icons, sprite) !default;

/// Retrive an attributes value for a specific icon in the sprite map
/// @param {string} $icon - The name of the icon
/// @param {string} $attr - The attribute you wish to retrieve (e.g. width)
@function sprite-attr($icon, $attr) {
	$newIcon: map-get($icons, $icon);
	@if $newIcon == null {
		@warn "Can't find an icon with the name #{$icon}";
	}
	@return map-get($newIcon, $attr);
}

/// Create a map with the specified icon of attributes
/// @param {string} $icon - The name of the icon
@function icon-attr($icon) {
	$attr: (
		width: sprite-attr($icon, width),
		height: sprite-attr($icon, height),
		x: sprite-attr($icon, backgroundX),
		y: sprite-attr($icon, backgroundY)
	);

	@return $attr;
}

/// Assign the correct SVG background image and dimensions to the element
%sprite {
	display: inline-block;
	background-image: url(map-get($sprite, svgPath));
	background-size: mq-px2em(map-get($sprite, width)) mq-px2em(map-get($sprite, height));
}
/// Assign the correct PNG background image and dimensions to the element
%ie-sprite {
	 background-image: url(map-get($sprite, pngPath));
}

/// Add a sprite PNG icon for browsers with the `$ieSprite` class with px for positioning
/// @param {string} $icon - The name of the icon
/// @param {string} $type [all] - The properties wanted (can be `all`, `size` or `bg`).
/// - `all` ensures the icon has the background, position and size.
/// - `size` is just for dimensions
/// - `bg` just  applies the backgrounds to an element
/// @example scss - Usage
///     @include ie-sprite(arrow);
@mixin ie-sprite($icon, $type: all) {
	$iconMap: icon-attr($icon);

	@if $ieSprite {
		#{$ieSprite} & {
			@if $type == all {
				// Shares the PNG background
				@extend %ie-sprite;
			}
			// Outputs dimensions of icon
			@if $type == all or $type == size {
				width: map-get($iconMap, width);
				height: map-get($iconMap, height);
			}
			// Outputs background position
			@if $type == all or $type == bg {
				background-position: (map-get($iconMap, x)) (map-get($iconMap, y));
			}
		}
	}
}

/// Add an SVG sprite icon using em positioning, with a PNG icon for browsers with the `$ieSprite` class using px positioning
/// @param {string} $icon - The name of the icon
/// @param {string} $type [all] - The properties wanted (can be `all`, `size` or `bg`).
/// - `all` ensures the icon has the background, position and size.
/// - `size` is just for dimensions
/// - `bg` just  applies the backgrounds to an element
/// @example scss - Usage
/// @include sprite(arrow);

@mixin sprite($icon, $type: all) {
	@if $type == all {
		// Shares the backgrounds
		@extend %sprite;
	}

	$iconMap: icon-attr($icon);

	// Outputs dimensions in em
	@if $type == all or $type == size {
		width: mq-px2em(map-get($iconMap, width) + 1);
		height: mq-px2em(map-get($iconMap, height) + 1);
	}

	// Outputs background position in em
	@if $type == all or $type == bg {
		background-position: mq-px2em(map-get($iconMap, x)) mq-px2em(map-get($iconMap, y));
	}

	// Add ie fallback
	@include ie-sprite($icon, $type);

}

//-----------------------------------------------------------------
// Cross Browser
//-----------------------------------------------------------------
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}
// Example usage - @include opacity(0.8);


%fill {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}


//-----------------------------------------------------------------
// Sitefinity Editor Only
//-----------------------------------------------------------------
@mixin editorOnly() {
	.sfPageEditor:not(.sfTemplateEditor) & { @content; }
}

@mixin inputPlaceholder() {
	&::-webkit-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {  /* Firefox 19+ */
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}
}
