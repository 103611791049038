.#{$prefix}directory {
	padding-top: 1em;

	&__flex-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	&__flex-item {
		display: block;

		@include bp(md) {
			display: flex;
		}
	}

	&__item {
		display: block;
		max-width: 100%; //ie10 fix
		flex-shrink: 1;  //ie10 fix
		margin: 0 0 2em;
		border-top: none;
		position: relative;
		color: $text-colour;
		background: $greyNurse2;
		border-bottom: 3px solid $greenAtlantis;
		text-decoration: none;

		&:first-child {
			margin: 0 0 1.5em;

			@include bp(md) {
				margin-bottom: 2em;
			}
		}

		&:hover,
		&:focus {
			color: $greyShark;

			.#{$prefix}directory {
				&__text {
					opacity: 1;
				}

				&__content {
					max-height: 100%;
				}

				&__title {
					color: $blueDenim;
					text-decoration: underline;

					&:after {
						margin-left: 0.4em;
						color: $blueDenim;
						text-decoration: underline;
					}

					&:after {
						text-decoration: none;
					}
				}

				&__image {
					img {
						transform: scale(1.05);
					}
				}
			}
		}

         &:visited{
			color: inherit;
		}

	}

	&__title {
		@include font-size(20px);
		color: $blueBiscay;
		text-decoration: none;
		transition: color 250ms ease-in-out;
		position: relative;
		padding-right: 30px;

		&:after {
			@extend .#{$prefix}icon;
			@include font-size(13px);
			line-height: 30px;
			padding: 0 0 0 10px;
			content: map-get($icon-defs, 'chevron-right');
			position: absolute;
			transition: $transition;
			margin-left: 0;
			color: $blueBiscay;
		}

		.is-external &:after {
			@extend .#{$prefix}icon;
			content: map-get($icon-defs, 'external');
			padding: 0.25em 0 0 0.5em;
			font-size: 0.76em;
			font-weight: 700;
		}
	}

	&__content {
		width: 100%;
		padding:  1em;
		background: $greyNurse2;
		transition: all 400ms ease-in-out;

		@include bp(md) {
			// position: absolute;
			// bottom: 0;
			// min-height: 5.5em;
			// max-height: 5.5em;
			// overflow: hidden;
		}
	}

	&__text,
	&__tags {
		@include bp(md) {
		// 	opacity: 0;
		// 	transition: opacity 250ms ease-in-out;
		}
	}

	&__image {
		display: none;

		@include bp(md) {
			display: block;
			overflow: hidden;
			margin: 0;

			img {
				width: 100%;
				transition: transform 300ms ease-in-out;
			}
		}
	}

}