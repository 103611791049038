/* ==========================================================================
   Fonts - Include custom fonts and icon fonts
   ========================================================================== */
$icon-font-path: "../icons/font" !default;
$icon-font-code: "mpt052";

@font-face {
	font-family: 'icons';
	src:url('#{$icon-font-path}/icons.eot?#{$icon-font-code}');
	src:url('#{$icon-font-path}/icons.eot?#iefix#{$icon-font-code}') format('embedded-opentype'),
		url('#{$icon-font-path}/icons.woff?#{$icon-font-code}') format('woff'),
		url('#{$icon-font-path}/icons.ttf?#{$icon-font-code}') format('truetype'),
		url('#{$icon-font-path}/icons.svg?#{$icon-font-code}#icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

span[class^="#{$prefix}icon--"],
span[class*=" #{$prefix}icon--"],
.#{$prefix}icon {
	font-family: 'icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: inherit;
	vertical-align: top;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

svg[class^="#{$prefix}icon--"],
svg[class*=" #{$prefix}icon--"] {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

$icon-defs: (
	double-chevron-left: "\e900",
	double-chevron-right: "\e901",
	external: "\e902",
	mute: "\e903",
	clock: "\e904",
	website: "\e905",
	menu: "\e906",
	pause: "\e907",
	play: "\e908",
	sound: "\e909",
	address: "\e90a",
	share-email: "\e91a",
	chevron-right: "\e90b",
	chevron-down: "\e90c",
	chevron-up: "\e90d",
	chevron-left: "\e90e",
	email: "\e90f",
	person: "\e91b",
	facebook: "\e910",
	home: "\e911",
	instagram: "\e912",
	linkedin: "\e913",
	pencil: "\e914",
	phone: "\e915",
	search: "\e916",
	tag: "\e917",
	twitter: "\e918",
	youtube: "\e919",
	fax: "\e920",
	document: "\e924",
	instagram-2: "\e91c",
	youtube-2: "\e91d",
	linkedin-2: "\e91e",
	facebook-2: "\e91f",
	twitter-2: "\e921",
	sort-arrow-ascend: "\e922",
	sort-arrow-descend: "\e923",
	mobile: "\e925",
	tick: "\e926",
	download: "\e927",
	info: "\e928",
	filter: "\e929",
	credit-card: "\e930",
	calendar: "\e92a",
	dollar: "\e92b",
	infringement: "\e92c",
	invoice: "\e92d",
	power: "\e92e",
	rates: "\e92f",
	dog: "\e931",
	cat: "\e932"
);

@each $icon, $code in $icon-defs {
	.#{$prefix}icon--#{$icon}:before {
		content: $code;
	}
}