.#{$prefix}banner {
	margin: -25px 0 2em;
	padding: 0 15px;

	@include bp(md) {
		position: relative;
		padding-top: 18em;
		margin: -25px 0 0;
		overflow: hidden;
	}

	&__image {
		display: none;

		@include bp(md) {
			display: block;
			position: absolute;
			clip: rect(0, auto, auto, 0);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			img {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
			}
		}
	}

	&__content {
		position: relative;
		background: #fff;

		@include bp(md) {
			padding: 1.3em 2em 2em;
			margin: 0 -2em;
		}

		h2 {
			text-align: center;
			text-transform: uppercase;
			color: $blueDenim;
			margin: 1em 0;

			@include bp(md) {
				margin-top: 0;
			}
		}
	}

	.#{$prefix}slideshow {
		margin: 0;
	}

	.#{$prefix}whats-happening h2 {
		@include font-size(16px);

		@include bp(sm) {
			@include font-size(20px);
		}
	}
}

// override to allow scrolling in CMS
.sfPageEditor.zeContentMode .#{$prefix}banner__image {
	@include bp(md) {
		img {
			position: absolute;
		}
	}
}