// ==========================================================================
// Errors
// ==========================================================================

 // PLEASE READ ME
 // Uses base error styling otherwise you can override
 // on a component basis
 //



// Error States
// ==========================================================================

.#{$prefix}form__error {
	color: $form--error-text;
	display: block;
	margin: 0 0 0.4em;
}

.#{$prefix}form__field-group.has-error {
	.#{$prefix}form {
		&__label {
			color: $form--error-text;
		}

		&__input {
			border-color: $form--error-text;
			background: $form--error-bkg;
		}
	}
}

// Error Placeholder Styles
//  Note: These Must be in this format/order for it to work, do not change/merge
// ========================================================================== */
.error::-webkit-input-placeholder {
	color: $form--error-text;
}

.error:-moz-placeholder {
	color: $form--error-text;
}

.error:-ms-input-placeholder {
	color: $form--error-text;
}

// Error Placeholder text colour on FOCUS
.error:focus::-webkit-input-placeholder {
	color: $form--text-focus;
}

.error:-moz-placeholder:focus {
	color: $form--text-focus;
}

.error:-ms-input-placeholder:focus {
	color: $form--text-focus;
}


// Error List that appears at top of form  if invalid
// ==========================================================================
.form__errors {
	color: $form--error-text;
}


#mc_embed_signup input.mce_inline_error {
	color: darken($form--error-text, 7) !important;
	border-color: $form--error-bkg !important;
	background: $form--error-bkg;

	&:focus {
		border-color: $form--error-border !important;
	}
}

#mc_embed_signup div.mce_inline_error {
	background: #fff !important;
	color: $form--error-text !important;
	font-weight: normal !important;
	margin: 0 !important;
	padding: 5px 0 !important;
}