.#{$prefix}footer {
	position: relative;
	background: $blueBiscay;
	color: #fff;
	padding: 100px 1em 2em;
	border-top: 5px solid #fff;

	h2 {
		color: #fff;
		@include font-size(24px);
		font-weight: 400;
	}

	a {
		color: #fff;

		&:hover,
		&:focus {
			color: $blueSinbad;
		}

		&:visited {
			color: #fff;

			&:hover,
			&:focus {
				color: $blueSinbad;
			}
		}
	}

	.#{$prefix}btn--inverse {
		color: $blueBiscay;

		&:hover,
		&:focus,
		&:visited,
		&:visited:hover,
		&:visited:focus {
			color: $blueBiscay;
		}
	}

	.nav--bottom {
		@include font-size(13px);
	}

	.nav--footer{
		text-align: center;

		@include bp(xs) {
			text-align: left;
		}
	}

	.#{$prefix}contact-details__phone {
		@include font-size(21px);
	}

	&__copyright {
		margin: 20px 0 0;

		@include bp(md) {
			margin: 0;
		}

		@include font-size(13px);
	}

	&__cta {
		@include font-size(13px);
		margin: 0 0 1em;

		&:first-child {
			border-top: 1px solid #4a648a;
			padding-top: 2em;

			@include bp(xs) {
				border-top: 0;
				padding-top: 0;
			}
		}

		&:last-child {
			padding-bottom: 2em;
		}

		h2 {
			display: none;

			@include bp(xs) {
				display: block;
			}
		}

		p {
			margin: 0 0 1.5em;
			display: none;

			@include bp(xs) {
				margin-bottom: 1em;
				display: block;
			}
		}

		.#{$prefix}btn {
			margin-bottom: 0;
		}
	}

	&__wave {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 83px;
		margin: -1px 0 0;

		img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 83px;
			width: 100%;
			min-width: 1200px;
			max-width: none;
		}
	}
}

.#{$prefix}social {
	margin: 30px 0;

	@include bp(md) {
		margin: 30px 0 0;
	}

	&__link {
		text-decoration: none;
		@include font-size(44px);
		display: inline-block;
		padding: 0 10px 0 0;

		@include bp(xs) {
			@include font-size(30px);
		}
	}
}