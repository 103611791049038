.#{$prefix}block--contact {
	background: $greyNurse2;
	padding: 1.5em;
	border-bottom: 3px solid $greenAtlantis;
	margin: 0 0 3em;

	&.has-map {
		padding: 0;
		background: none;
		border: 0;

		.#{$prefix}block--contact__heading {
			margin-top: 1em;

			@include bp(md) {
				margin-top: 0;
			}
		}
	}

	&__map {
		margin-bottom: 3em;

		iframe {
			width: 100%;
			border: 0;
			min-height: 320px;
		}

		.#{$prefix}block--contact.has-map & {
			margin-bottom: 1em;
		}
	}

	[class*=#{$prefix}icon--] {
		color: $blueBiscay;
	}
}

.#{$prefix}contact-details {
	margin-left: 30px;
	list-style: none;

	&:after {
	    content: "";
	    display: table;
	    clear: both;
	}

	&__item {
		margin-left: -30px;
		display: inline-block;
		vertical-align: top;

		.has-map & {
			width: 100%;

			&.hours,
			&.address {
				margin-bottom: 14px;
			}
		}
	}

	&__label {
		font-weight: bold;
		text-transform: uppercase;
		@include font-size(13px);
		float: left;
		width: 20px;
		margin: 0 0 1em;
		line-height: 25px;
		clear: left;
		margin-left: -30px;

		[class*=#{$prefix}icon--] {
			@include font-size(16px);
		}
	}

	&__detail {
		float: left;
		margin: 0;
		line-height: 24px;
	}

	&--inline {
		margin: 0;

		.#{$prefix}contact-details {

			&__item {
				display: inline-block;
				white-space: nowrap;
				margin: 0 30px 0 -30px;
			}

			&__label {
				display: inline-block;
				margin: 0;
				float: none;
				clear: none;
				line-height: 13px;
				vertical-align: top;
				margin-top: 22px;

				[class*=#{$prefix}icon--] {
					display: inline-block;
				}
			}

			&__detail {
				display: inline-block;
				float: none;
				padding: 15px 25px 0 0;
				white-space: normal;
			}

			&__phone {
				@include font-size(15px);
			}
		}
	}
}