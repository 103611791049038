/* ==========================================================================
   Button Styles
   ========================================================================== */

/**
 * PLEASE READ ME
 * Theming: Standard buttons and any mofication styles such as large, small
 * primary, secondary etc.
 *
 * Formatting options: input, link buttons etc
 *
 * Button Groups: Alignment, containers and group styling
 *
 * Optimisation
 * If you are sharing values e.g. hover and focus colour merge the
 * values so we are not outputting bloated uneccessary css.
 *
 */

// ==========================================================================
//   Base Button Styling
// ==========================================================================

// Sizing - Standard Buttons
$btn-size: 44px;
$btn-font-size: 15px;
$btn--padding-vertical: 12px;
$btn--padding-horizontal: 24px;

// Sizing - Small button
$btn-size--small: 44px;
$btn-font-size--small: $btn-size--small / 2;
$btn--small-padding-vertical: 6px;
$btn--small-padding-horizontal: 22px;

// Sizing - Large Buttons
$btn-size--large: 68px;
$btn-font-size--large: $btn-size--large / 2;
$btn--large-padding-vertical: 16px;
$btn--large-padding-horizontal: 52px;

// Spacing
$btn-margin: 1em;

$btn--transition: $transition;

// ==========================================================================
//   Button Themes!
//  =========================================================================
// Buttons Primary
$btn-fill: $blueDenim;
$btn-text: #fff;
$btn-highlight: $blueSinbad;

$btn-fill--hover: $blueTory;
$btn-text--hover: #fff;
$btn-highlight--hover: $blueBiscay;

$btn-fill--focus: $blueVenice;
$btn-text--focus: #fff;
$btn-highlight--focus: $blueSinbad;

$btn-fill--active: $btn-fill--focus;
$btn-text--active: $btn-text--focus;
$btn-highlight--active: $btn-highlight--focus;

// Buttons Secondary
$btn-fill--secondary: $blueSinbad;
$btn-text--secondary: $blueChatham;
$btn-highlight--secondary: $btn-fill--secondary;

$btn-fill--secondary-hover: $blueBiscay;
$btn-text--secondary-hover: #fff;
$btn-highlight--secondary-hover: $btn-fill--secondary-hover;

$btn-fill--secondary-focus: $blueBaked;
$btn-text--secondary-focus: $blueBiscay;
$btn-highlight--secondary-focus: $btn-fill--secondary-focus;

$btn-fill--secondary-active: $btn-fill--secondary-focus;
$btn-text--secondary-active: $btn-text--secondary-focus;
$btn-highlight--secondary-active: $btn-fill--secondary-active;

// Buttons Inverse
$btn-fill--inverse: #fff;
$btn-text--inverse: $blueBiscay;
$btn-highlight--inverse: $blueSinbad;

$btn-fill--inverse-hover: $blueSinbad;
$btn-text--inverse-hover: $blueBiscay;
$btn-highlight--inverse-hover: $blueDenim;

$btn-fill--inverse-focus: $blueSinbad;
$btn-text--inverse-focus: $blueBiscay;
$btn-highlight--inverse-focus: $blueDenim;

$btn-fill--inverse-active: $btn-fill--inverse-focus;
$btn-text--inverse-active: $btn-text--inverse-focus;
$btn-highlight--inverse-active: $btn-highlight--inverse-focus;

// Buttons Disabled
$btn-fill--disabled: $greyGallery;
$btn-text--disabled: $greyShark;
$btn-highlight--disabled: $greyNurse;

/* ==========================================================================
   Standard Button Specific Styles
   ========================================================================== */
.#{$prefix}btn {
  @include font-size($btn-font-size);
  display: inline-block;
  box-sizing: border-box;
  transition: $btn--transition;
  text-align: center;
  text-decoration: none;
  color: $btn-text;
  background-color: $btn-fill;
  margin: 0 $btn-margin $btn-margin 0;
  padding: 12px (24px + $btn-size / 2) 12px 24px;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    border-right: $btn-size / 2 solid $btn-highlight;
    transition: $btn--transition;
  }

  &:before {
    top: 0;
    border-bottom: $btn-size / 2 solid transparent;
  }

  &:after {
    bottom: 0;
    border-top: $btn-size / 2 solid transparent;
  }

  &:hover {
    color: $btn-text--hover;
    background-color: $btn-fill--hover;

    &:before,
    &:after {
      border-right-color: $btn-highlight--hover;
    }
  }

  &:visited {
    color: $btn-text;
  }

  &:focus {
    color: $btn-text--focus;
    background-color: $btn-fill--focus;
    outline: none;

    &:before,
    &:after {
      border-right-color: $btn-highlight--focus;
    }
  }

  &:active {
    color: $btn-text--active;
    background-color: $btn-fill--active;

    &:before,
    &:after {
      border-right-color: $btn-highlight--active;
    }
  }
}
// Back Button
.#{$prefix}btn--back {
  padding: 12px 24px 12px (24px + $btn-size / 2);

  &:before,
  &:after {
    left: 0;
    border-right: 0;
    border-left: $btn-size / 2 solid $btn-highlight;
  }

  &:hover {
    &:before,
    &:after {
      border-left-color: $btn-highlight--hover;
    }
  }

  &:focus {
    &:before,
    &:after {
      border-left-color: $btn-highlight--focus;
    }
  }

  &:active {
    &:before,
    &:after {
      border-left-color: $btn-highlight--active;
    }
  }
}

/* Icon Button Specific Styles
   ========================================================================== */
.#{$prefix}btn--icon {
  line-height: $btn-size;
  width: $btn-size;
  height: $btn-size;
  margin: 0;
  padding: 0;

  &:before,
  &:after {
    content: none;
  }
}

.#{$prefix}btn__icon {
  display: inline-block;
}

/* Button Sizes
   ========================================================================== */
.#{$prefix}btn--large {
  padding: calculateRem($btn--large-padding-vertical) calculateRem($btn--large-padding-horizontal);
}

.#{$prefix}btn--icon-large {
  @include font-size($btn-font-size--large);
  line-height: $btn-size--large;
  width: $btn-size--large;
  height: $btn-size--large;
}

.#{$prefix}btn--small {
  padding: calculateRem($btn--small-padding-vertical) calculateRem($btn--small-padding-horizontal);
}

/* Themes/Colours
 * Overrides base button style
   ========================================================================== */
.#{$prefix}btn--secondary {
  color: $btn-text--secondary;
  background-color: $btn-fill--secondary;
  padding: 12px 24px;

  &:before,
  &:after {
    content: none; // remove this if you want the highlights back
    border-right-color: $btn-highlight--secondary;
  }

  &:visited {
    color: $btn-text--secondary;
  }

  &:hover {
    color: $btn-text--secondary-hover;
    background-color: $btn-fill--secondary-hover;

    &:before,
    &:after {
      border-right-color: $btn-highlight--secondary-hover;
    }
  }

  &:focus {
    color: $btn-text--secondary-focus;
    background-color: $btn-fill--secondary-focus;
    outline: none;

    &:before,
    &:after {
      border-right-color: $btn-highlight--secondary-focus;
    }
  }

  &:active {
    color: $btn-text--secondary-active;
    background-color: $btn-fill--secondary-active;

    &:before,
    &:after {
      border-right-color: $btn-highlight--secondary-active;
    }
  }
}

.#{$prefix}btn--inverse {
  color: $btn-text--inverse;
  background-color: $btn-fill--inverse;
  border: 1px solid;
  border-color: $btn-fill--inverse;

  &:before,
  &:after {
    border-right-color: $btn-highlight--inverse;
  }

  &:visited {
    color: $btn-text--inverse;
  }

  &:hover {
    color: $btn-text--inverse-hover;
    background-color: $btn-fill--inverse-hover;
    border-color: $btn-fill--inverse-hover;

    &:before,
    &:after {
      border-right-color: $btn-highlight--inverse-hover;
    }
  }

  &:focus {
    color: $btn-text--inverse-focus;
    background-color: $btn-fill--inverse-focus;
    outline: none;
    border-color: $btn-fill--inverse;

    &:before,
    &:after {
      border-right-color: $btn-highlight--inverse-focus;
    }
  }

  &:active {
    color: $btn-text--inverse-active;
    background-color: $btn-fill--inverse-active;

    &:before,
    &:after {
      border-right-color: $btn-highlight--inverse-active;
    }
  }
}

// Disabled Button
.#{$prefix}btn--disabled {
  cursor: no-drop;
  color: $btn-text--disabled;
  background-color: $btn-fill--disabled;

  &:before,
  &:after {
    border-right-color: $btn-highlight--disabled;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: $btn-text--disabled;
    background-color: $btn-fill--disabled;

    &:before,
    &:after {
      border-right-color: $btn-highlight--disabled;
    }
  }
}

/* Modifiers
   ========================================================================== */
.#{$prefix}btn--block {
  width: 100%;
}

// Full width mobile only.
.#{$prefix}btn--block-mob {
  width: 100%;

  @include bp(xs) {
    display: inline-block;
    width: auto;
  }
}

/* Wrappers/Containers
   ========================================================================== */
.#{$prefix}btn-container {
  margin: $global-margin 0;
}

.#{$prefix}btn-container--right {
  text-align: right;
}

.#{$prefix}btn-container--left {
  text-align: left;
}

.#{$prefix}btn-container--center {
  text-align: center;
}

.#{$prefix}btn-container--right-large {
  text-align: center;

  @include bp(lg) {
    text-align: right;
  }
}

.#{$prefix}btn-container--left-large {
  text-align: center;

  @include bp(lg) {
    text-align: center;
  }
}

/* Specific overrides
   ========================================================================== */
// Subscribe to eNews, needs top margin to distance
// button from mailchimp generated error messages
#mc_embed_signup .sp-btn {
  margin-top: 1em;
}

.sp-btn {
  &.js-my-south-perth {
    border: 2px solid #fff;
    height: 39px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0;
    padding: 11px;

    &:after,
    &:before {
      border: none;
      content: none;
      position: relative;
    }

    &:after {
      color: #fff;
      margin-left: 3px;
      @extend .#{$prefix}icon;
      @include font-size(12px);
      content: map-get($icon-defs, "chevron-right");
    }

    &:hover,
    &:focus {
      background-color: #1d3d71;
    }
  }
}
