html {
	font-size: 1em;
	line-height: 1.4;
	box-sizing: border-box;
	font-size: $baseFontSize;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	@include font-size(15px);
	font-family: $font-default;
	color: $greyShark;
	overflow-x: hidden;
}

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

audio,
canvas,
img,
video {
	vertical-align: middle;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

textarea {
	resize: vertical;
}

form {
	display: block;
}

button {
	padding: 0;
	border: 0;
}

iframe {
	border: 0;
	max-width: 100%;
}