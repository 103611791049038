// ==========================================================================/**
// Input Styles
// ==========================================================================/**

.#{$prefix}form__input {
	width: 100%;
	margin: 0;
	padding: 0.8em 1em;
	color: $form--text;
	border: 1px solid $form--border;
	border-radius: 0;
	outline: none;
	background: $form--fill;
	box-sizing: border-box;
	transition: $transition;
	-webkit-appearance: none; //remove shadow in iOS

	// placeholder
	@include inputPlaceholder() {
		color: $form--placeholder;
	}

	// focus
	.has-focus & {
		color: $form--text-focus;
		background: $form--focus;
		border: 1px solid $form--border-focus;

		@include inputPlaceholder() {
			color: $form--placeholder;
		}
	}

	// If horizontal form set to inline block
	.#{$prefix}form--inline & {
		@include bp(md) {
			display: inline-block;
			width: auto;
			margin: 0 1em;
		}
	}

	// Disabled
	.is-disabled &,
	&[disabled],
	&[readonly] {
		background: $form--disabled;
		color: $form--disabled-text;
		cursor: not-allowed;
		border: 1px solid #fff;

		@include inputPlaceholder() {
			color: $form--disabled-text;
		}
	}
}
