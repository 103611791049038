.#{$prefix}header {
  z-index: 201;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 250ms ease-in-out;

  @include bp(md) {
    position: relative;
    width: auto;
  }

  &.is-hidden {
    transform: translate(0, -100%);

    @include bp(md) {
      transform: none;
    }
  }

  &__top {
    @include font-size(13px);
    padding: 1em;
    background: $blueDenim;
    display: none;

    @include bp(md) {
      padding: 1em 0;
      display: block;
    }

    a {
      color: #fff;

      &:hover,
      &:focus {
        color: $greyNurse;
      }
    }

    .#{$prefix}container {
      @include bp(md) {
        padding: 0 2em;
      }
    }

    .rm-pd-right {
      padding-right: 0;
    }
  }

  &__main {
    padding: 1em 0;
    background: #fff;
    position: relative;
    border-bottom: 2px solid $greyNurse;
    height: 100px;

    @include bp(md) {
      position: static;
      height: auto;
      margin: 0 0 23px;
    }

    .#{$prefix}container {
      padding: 0;

      @include bp(md) {
        padding: 0 2em;
      }
    }
  }

  &__logo {
    margin: 8px 135px 0 15px;

    a {
      border: 2px solid #fff;
      transition: $transition;
      display: block;
      width: 187px;
      margin: -12px -7px;
      padding: 10px 5px;

      &:hover,
      &:focus {
        border-color: #ddd;
      }
    }

    @include bp(md) {
      padding: 0.6em 0;
      margin: 0;
    }

    img {
      height: 50px;
      width: auto;

      @include bp(md) {
        height: auto;
        width: 173px;
      }
    }

    h1 {
      margin: 0;
    }
  }

  &__buttons {
    position: absolute;
    top: 0;
    right: 0;

    @include bp(md) {
      display: none;
    }

    button {
      @include font-size(13px);
      width: 60px;
      height: 100px;
      text-transform: uppercase;
      display: block;
      float: left;
      background: none;
      color: $blueDenim;
      transition: $transition;

      &:hover,
      &:focus {
        outline: none;
        background: $blueDenim;
        color: #fff;
      }

      [class*="#{$prefix}icon"] {
        @include font-size(26px);
        display: block;
      }
    }
  }

  &__skip-to-main {
    display: none;

    @include bp(md) {
      padding: 0.85em 0;
      display: block;

      opacity: 0;
      &:active,
      &:focus {
        opacity: 1;
      }
    }
  }

  &__i-want-to {
    display: none;

    @include bp(md) {
      position: relative;
      display: inline-block;
      margin: 0 0 0 0.5em;

      .#{$prefix}icon--pencil {
        @include font-size(16px);
        color: $blueBiscay;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        height: 39px;
        line-height: 39px;
        width: 39px;
        text-align: center;
      }

      .#{$prefix}nav--select {
        display: inline-block;
        width: 250px;
      }

      .custom-select.active span {
        border: 0;
      }
    }
    @include bp(lg) {
      margin: 0 0 0 2em;
    }
  }

  .#{$prefix}nav--top {
    display: none;

    @include bp(md) {
      display: inline-block;
      //   margin: 0 -1em;

      .#{$prefix}nav__link {
        padding: 0 0.5em;

        @include bp(lg) {
          padding: 0 1em;
        }
      }
    }
  }
}
