.#{$prefix}block--faqs {
	margin: 0 -1em 3em;
	@extend .#{$prefix}clearfix;

	&__item {
		padding: 0 1em;
		margin: 0 0 2em;

		&:nth-child(odd) {
			clear: left;
		}

		@include bp(sm) {
			width: 50%;
			float: left;
		}
	}

	&__question {
		@include font-size(15px);
		color: $greyShark;
		margin: 0 0 0.5em;
	}

	// &__answer {}

	&__label {
		@include font-size(18px);
		color: $blueDenim;
		font-weight: 700;
	}
}

