// ==========================================================================
//   Alerts
// ==========================================================================

/**
 * PLEASE READ ME
 * Alert demos.
 * This has been setup to allow you to easily add and remove colour options for
 * alerts without having to generate a lot of code. Please read the comments below
 * explaining the use of arrays.
 */

$critical: $error;
$success: $success;
$warning: $warning;

/**
 * Array for the output of alert boxes, you can simply add additional colour
 * options to this and the appropriate selectors will be created.
 *
 * It consists of 3 pieces of data
 * 1. Name of alert e.g. success
 * 2. Alert colour scheme Variable (defined at top of the file)
 *	  this is used for colouring the background/border of the alert
 * 3. Text Color: this is used to update the text of the alert. Make sure
 *    WCAG AA colour contrast ratios apply.
 * 4. Icon colour for alerts with borders.
 * 5. Icon colour for alerts with background color.
 *
 */

$alertTypes: (
success $success $text-colour $success $text-colour,
warning $warning $text-colour $warning $text-colour,
critical $critical $text-colour $critical $text-colour
);

@each $current-type in $alertTypes {
	$backgroundColor: nth($current-type, 2);

	.#{$prefix}alert--#{"" + nth($current-type, 1)} {
		background: $greyNurse;
		border: 1px solid #d9d9d9;
		border-bottom-color: #{"" + nth($current-type, 2)};
		border-bottom-width: 3px;
		color:  #{"" + nth($current-type, 3)};

		&.#{$prefix}alert--border {
			background: rgba($backgroundColor, 0.1);
			border-color: #{"" + nth($current-type, 2)};
			border-width: 2px;

			.#{$prefix}alert__icon {
				color: #{"" + nth($current-type, 4)};
				border-color: #{"" + nth($current-type, 4)};
			}
		}

		.#{$prefix}alert__icon {
			color: #{"" + nth($current-type, 5)};
		}
	}
}


.#{$prefix}alert {
	padding: 1em;
	margin-bottom: 2em;
	border-radius: 2px;
	display: flex;
	align-content: stretch;

	&--border {
		background: none;
		border-width: 3px;
		border-style: solid;
	}

	&__title {
		@include font-size(18px);
		margin: 0;
	}

	&__icon {
		flex: 0 1 auto;
		align-self: center;
		font-size: 1.4em;

		.#{$prefix}alert--border & {
			border-width: 2px;
			border-style: solid;
			border-radius: 15px;
			width: 30px;
			height: 30px;
			font-size: 1.2em;
			line-height: 26px;
			text-align: center;
		}

		span[class^="#{$prefix}icon--"] {
			display: block;
			width: 27px;
			text-align: center;
		}
	}

	&__content {
		flex: 3 1 auto;
		align-self: auto;
		padding-left: 1em;

		.#{$prefix}alert__description {
			margin-top: 4px;
		}
	}
}












// No Flexbox fallback
// Floats are used for no flexbox fallback, no vertical
// centering in these browsers
// ==================================================================
.no-flexbox .#{$prefix}alert {
	display: block; // Needed for android 4.3 below

	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}

.no-flexbox .#{$prefix}alert__icon {
	float: left;
}

.no-flexbox .#{$prefix}alert__content {
	float: left;
	width: 80%;
}
