.#{$prefix}payment {
	&__item {
		border-bottom: 3px solid $greyNurse;
		background: $greyNurse2;
		padding: 2em 1em 0.5em 1em;
		margin-bottom: 2em;

		&.is-active {
			border-bottom: 3px solid $blueDenim;

			.#{$prefix}payment__more a:after {
				// content: map-get($icon-defs, 'chevron-up');
				transform: scaleY(-1);
			}
		}
	}

	&__item + &__item  {
		margin-top: -2em;
	}

	&__content {
		padding-bottom: 1em;
	}

	&__link {
		margin-top: 1em;
		@include bp(xs) {
			margin-top: 0;
			text-align: right;
		}
	}

	&__more {
		@include font-size(14px);
		display: inline-block;
		margin-top: 0;
		padding-right: 25px;
		position: relative;

		@include bp(sm) {
			margin-top: 1.2em;
		}

		a {
			color: $blueBiscay;
			font-weight: bold;
			text-transform: uppercase;
			text-decoration: none;

			&:hover,
			&:focus {
				color: lighten($blueBiscay, 10%);
			}

			&:after {
				@extend .#{$prefix}icon;
				@include font-size(13px);
				content: map-get($icon-defs, 'chevron-down');
				position: absolute;
				color: $blueBiscay;
				right: 0;
				top: 0px;
				margin: 0 0 -9px 0;
				text-align: right;
				transition: transform 250ms ease-in-out;
			}
		}
	}

	&__details {
		display: none;
		overflow: hidden;
		padding-bottom: 1em;
	}
}



.#{$prefix}payment-option {
	border: 2px solid #fff;
	background: $greyNurse2;
	background: linear-gradient(to bottom, $greyNurse2 0%, $greyNurse2 50%, $greyNurse 50%, $greyNurse 100%);
	color: $blueDenim;
	text-align: center;
	display: block;
	height: 190px;
	padding: 10px;
	margin-bottom: 1em;
	transition: $transition;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		border: 2px solid $blueDenim;
	}

	&__icon {
		font-size: 4.4em;
		padding-top: 18px;
		height: 115px;

		.#{$prefix}icon--power {
			@include font-size(54px);
		}
	}

	&__title {
		@include font-size(18px);
		font-weight: bold;
		line-height: 1.2;
		padding-top: 0.35em;
	}
}
