// ==========================================================================
//   GENERIC TYPOGRAPHY STYLES
// ==========================================================================


/**
 * PLEASE READ ME
 * There should be absolutely NO classes, IDs etc in these files.
 * This is for html elements only.
 *
 * Classes, ids etc should be references on a component basis or added in
 * in the components file as shared styles.
 *
 */

 h1, h2, h3 {
 	margin: 0 0 0.6em;
 }

 h1 {
 	@include font-size(40px);
 	font-weight: 400;
 	color: $blueChatham;
 	margin: 0 0 15px;
 	line-height: 1.2;
 }

 h2 {
 	@include font-size(20px);
 	color: $blueBiscay;
 	margin: 0 0 10px;
 }

 h3 {
 	@include font-size(18px);
 	color: $blueDenim;
 	margin: 0 0 10px;
 }

 h4 {
 	@include font-size(14px);
 	color: $blueDenim;
 	margin: 0 0 10px;
 	text-transform: uppercase;
 	font-weight: 400;
 }

 .#{$prefix}block--content ul,
 .#{$prefix}accordion__panel ul,
 .#{$prefix}news__text ul {
 	padding: 0;
 	margin: 0 0 20px;
 	list-style: none;

 	&:last-child {
 		margin-bottom: 0;
 	}

 	li {
 		margin: 10px 0 0 15px;
 		padding: 0 0 0 25px;
 		position: relative;

 		&:first-child {
 			margin-top: 0;
 		}

 		&:before {
 			content: '';
 			position: absolute;
 			left: 0;
 			top: 8px;
 			width: 7px;
 			height: 7px;
 			background: $blueDenim;
 			border-radius: 7px;
 		}

 		li {
 			margin: 10px 0 0;

 			&:after {
 				content: '';
 				position: absolute;
 				left: 2px;
 				top: 10px;
 				width: 3px;
 				height: 3px;
 				border-radius: 3px;
 				background: #fff;
 			}
 		}
 	}
 }

 .#{$prefix}block--content ol,
 .#{$prefix}accordion__panel ol,
 .#{$prefix}news__text ol {
 	padding: 0;
 	margin: 0 0 20px;
 	list-style: none;
 	counter-reset: list-counter;

 	& > li {
 		counter-increment: list-counter;
 		margin: 10px 0 0 15px;
 		padding: 0 0 0 27px;
 		position: relative;

 		&:first-child {
 			margin-top: 0;
 		}

 		&:before {
 			content: counter(list-counter) '.';
 			position: absolute;
 			left: 0;
 			top: 0;
 			font-weight: bold;
 		}

 		& > ol {
 			counter-reset: none;
 			margin: 0;

 			& > li {
 				counter-increment: list-sub-counter;
 				margin: 10px 0 0;

 				&:before {
 					content: counter(list-counter) '.' counter(list-sub-counter);
 				}

 				& > ol {
 					margin: 0;
 					counter-reset: none;

 					& > li {
 						counter-increment: list-sub-sub-counter;
 						padding: 0 0 0 35px;

 						&:before {
 							content: counter(list-counter) '.' counter(list-sub-counter) '.' counter(list-sub-sub-counter);
 						}
 					}
 				}
 			}
 		}
 	}
 }

 p {
 	margin: 0 0 1em;

 	&:last-child {
 		margin: 0;
 	}

 	&.lead {
 		@include font-size(20px);
 	}
 }

 a {
 	color: $blueAzure;
 	transition: $transition;
 	&:visited,
 	&:focus,
 	&:active {
 		color: $purpleGalaxy;
 	}

 	&:hover,
 	&:focus,
 	&:active {
 		text-decoration: none;
 	}


 	&[href^='tel:'] {
 		@include bp(md) {
 			color: inherit !important;
 			text-decoration: none;
 			cursor: default;

 			&:hover,
 			&:focus,
 			&:visited,
 			&:active {
 				color: inherit !important;
 				text-decoration: none;
 			}
 		}
 	}

 	.#{$prefix}block--content &[rel=external] {
 		&:after {
 			@extend .#{$prefix}icon;
 			content: map-get($icon-defs, 'external');
 			display: inline-block;
 			@include font-size(12px);
 			padding: 0.22em 0 0 0.5em;
 			text-decoration: none;
 		}
 	}

 	.large-link & {
 		@include font-size(20px);
 		color: $blueBiscay;
 		font-weight: bold;
 		text-decoration: none;

 		&:after {
 			@extend .#{$prefix}icon;
 			@include font-size(13px);
 			line-height: 30px;
 			padding: 0 0 0 10px;
 			content: map-get($icon-defs, 'chevron-right');
 			position: absolute;
 			transition: $transition;
 			margin-left: 0;
 			color: $blueBiscay;
 		}

 		&:hover,
 		&:focus,
 		&:active {
 			color: $blueDenim;

 			&:after {
 				margin-left: 0.4em;
 				color: $blueDenim;
 				text-decoration: none;
 			}
 		}

 		&:visited {
 			&:hover,
 			&:focus,
 			&:active {
 				color: $blueDenim;
 			}
 		}

 		&[rel=external] {
 			&:after {
 				@include font-size(15px);
 			}
 		}
 	}
	
 }

 hr {
 	display: block;
 	height: 1px;
 	border: 0;
 	border-top: 1px solid $greyNurse;
 	margin: 1em 0;
 	padding: 0;
 }

 .#{$prefix}left {
 	text-align: left;
 }

 .#{$prefix}right {
 	text-align: right;
 }