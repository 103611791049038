// ==========================================================================
//   GENERIC MEDIA STYLES
// ==========================================================================


/**
 * PLEASE READ ME
 * There should be absolutely NO classes, IDs etc in these files.
 * This is for html elements only.
 *
 * Classes, ids etc should be references on a component basis or added in
 * in the components file as shared styles.
 *
 */

img {
	max-width: 100%;
	height: auto;
}