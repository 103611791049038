.#{$prefix}news {
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		margin: 0 0 0 -25px;
		border-top: 25px solid #fff;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
	}

	h2 {
		text-align: center;
		text-transform: uppercase;
		color: $blueDenim;
		margin: 0 0 1em;

		@include bp(md) {
			margin-bottom: 2em;
		}
	}

	&__listing {
		.sp-news__meta {
			margin-bottom: 0.3em;
		}

		.sp-news__text {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	&__date-group {
		@include font-size(14px);
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 0 0.7em;
		color: $blueDenim;
	}

	&__item {
		display: block;
		padding: 0 0 2.8em;
		text-align: left;
		clear: both;
	}

	&__image {
		display: none;

		@include bp(md) {
			display: block;

			img {
				width: 100%;
				transition: transform 400ms ease-in-out;
			}
		}
	}

	&__content {
		a {
			text-decoration: none;
		}
	}

	&__title {
		@include font-size(20px);

		a {
			color: $blueBiscay;
			text-decoration: none;
			position: relative;

			span:after {
				@extend .#{$prefix}icon;
				@include font-size(13px);
				display: inline-block;
				padding: 0.5em 0 0 0.3em;
				color: $blueBiscay;
				content: map-get($icon-defs, 'chevron-right');
				position: absolute;
				transition: $transition;
			}

			&:hover,
			&:focus,
			&:active {
				color: $blueDenim;
				text-decoration: underline;

				span:after {
					color: $blueDenim;
					margin-left: 0.4em;
					text-decoration: underline;
				}

				span:after {
					text-decoration: none;
				}
			}
		}
	}

	&__text {
		display: block;
		padding-bottom: 1em;
	}

	&__meta {
		@include font-size(14px);
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 0 0.7em;
	}

	&__type {
		color: $blueDenim;
		text-decoration: none;

		&:after {
			content: '|';
			color: $text-colour;
			display: inline-block;
			padding: 0 3px 0 7px;
		}
	}

	// &__date {}

	&__tags {
		@include font-size(13px);
		display: block;
		text-transform: uppercase;
		display: inline-block;
		margin: 0 0 10px;
		padding: 0 0 0 24px;

		.#{$prefix}icon--tag {
			@include font-size(13px);
			color: $yellowEnergy;
			margin-left: -23px;
    		margin-right: 6px;

			@include bp(md) {
				@include font-size(15px);
				margin-right: 5px;
			}
		}
	}


	&__tag {
		text-decoration: none;

		&:after {
			content: '•';
			display: inline-block;
			padding: 0 3px 0 7px;
		}

		&:last-child:after {
			content: '';
		}
	}

	.#{$prefix}btn {
		@include bp(md) {
			margin: 3em 0 1.5em;
		}
	}
}


.#{$prefix}news-extra {
	/*border-top: 1px solid $greyNurse;*/
	padding: 1em 0;
    margin-top: 1em;

	&__title {
		@include font-size(14px);
		text-transform: uppercase;
		color: $blueDenim;
		font-weight: 600;
		margin-bottom: 1.8em;
	}

	.#{$prefix}document {
		border-top: 0;
		margin-bottom: 2em;
	}

	.#{$prefix}block--social {
		margin-bottom: 2em;
	}

	.#{$prefix}block--contact__map {
		margin-bottom: 3em;
	}

	.#{$prefix}block--contact {
		position: relative;
		margin-top: 3.5em;

		&:before {
			content: '';
			border-top: 1px solid #e0e0e0;
			display: block;
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			margin: 0 0 2em;
		}
	}


}