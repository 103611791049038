
// ==========================================================================
//   Slideshow
// ==========================================================================


/**
 * PLEASE READ ME
 * Base styles can be found in the default slick slider styles
 * inside the vendor folder.
 *
 * These are Base overrides, and therefore are included in the
 * main scss AFTER the vendors files not with the other
 * components.
 *
 *
 */

$slideshow-button-width: 36px;

.#{$prefix}slideshow {
	position: relative;
	margin: 0 -20px 3em;

	&:after {
		content: '';
		border-bottom: 3px solid $blueDenim;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 20px;
	}

	.#{$prefix}banner & {
		margin: 0 -20px;
	}

	@include bp(lg) {
		min-height: 490px;
	}

	&__slide {
		outline: none;
		padding: 0 20px;
	}

	&__image {
		width: 100%;
		margin: 0 0 1.2em;
	}

	&__title {
		@include font-size(24px);
		line-height: 1em;
		margin: 0;
		padding-right: 33px;
		color: $blueBiscay;

		@include bp(lg) {
			@include font-size(30px);
			line-height: 36px;
		}

		a {
			color: $blueBiscay;
			text-decoration: none;
			position: relative;

            &:after {
	            @extend .#{$prefix}icon;
				@include font-size(18px);
				line-height: 25px;
				padding: 0 0 10px 15px;
	            content: map-get($icon-defs, 'chevron-right');
				transition: $transition;
				position: absolute;
				margin-left: 0;
				color: $blueBiscay;

				@include bp(lg) {
					line-height: 40px;
					padding-bottom: 0;
				}
            }

			&:hover,
			&:focus,
			&:active {
                color: $blueDenim;
				text-decoration: underline;

				&:after {
                	color: $blueDenim;
					margin-left: 0.4em;
					text-decoration: underline;
				}

				&:after {
					text-decoration: none;
				}
			}
		}
	}

	&__text {
		@include font-size(18px);
		padding: 0 0 1.5em;
		color: $greyShark;
	}

	&__buttons {
		display: none;
		position: absolute;
		top: -75px;
		right: 40px;
		padding-top: 44.5%;

		@include bp(xs) {
			display: block;
		}
	}

	&__button {
		width: $slideshow-button-width;
		height: $slideshow-button-width;
		border: 3px solid #fff;
		color: #fff;
		text-align: center;
		border-radius: 100px;
		background: rgba(#000,0.2);
		position: absolute;
		right: 0;
		transition: opacity 250ms ease-in-out;

		&:hover,
		&:focus {
			outline: none;
			background: rgba(#000,0.5);
		}
	}

	&--play {
		opacity: 0;
		z-index: 0;
		display: none;

		.slick-paused & {
			opacity: 1;
			z-index: 2;
			display: block;
		}
	}

	&--pause {
		opacity: 1;
		z-index: 2;
		display: block;

		.slick-paused & {
			opacity: 0;
			z-index: 0;
			display: none;
		}
	}


// ==========================================================================
//   Next and Previous Arrows
//  =========================================================================
	.slick-arrow {
		width: auto;
		height: auto;
		top: 0;
		padding-top: 22.25%;
		margin: (-$slideshow-button-width / 2) 0 0;
		transform: none;

		&:before {
			@include font-size(16px);
			width: $slideshow-button-width;
			height: $slideshow-button-width;
			color: #fff;
			opacity: 1;
			line-height: 28px;
			background: rgba(#000,0.2);
			border: 3px solid #fff;
			border-radius: 100px;
			text-align: center;
			display: block;
		}

		&:hover,
		&:focus {
			&:before {
				background: rgba(#000,0.5);
			}
		}
	}

	.slick-prev {
		left: 40px;
		z-index: 1;

		&:before {
			padding-right: 3px;
		}
	}

	.slick-next {
		right: 40px;

		&:before {
			padding-left: 3px;
		}
	}

	.slick-dots {
		top: 0;
		padding-top: 44.5%;
		bottom: initial;
		text-align: right;
		margin: -65px 0 0;
		width: auto;
		right: 80px;

		li {
			button {
				display: none;
				background: #fff;
				border: 1px solid $blueBiscay;
				border-radius: 100px;
				width: 14px;
				height: 14px;

				@include bp(xs) {
					display: block;
				}

				&:before {
					content: none;
				}

				&:hover,
				&:focus {
					background: $blueDenim;
				}
			}

			&.slick-active button {
				background: $blueBiscay;

				&:hover,
				&:focus {
					background: $blueDenim;
				}
			}
		}
	}

	.slick-slider {
		margin: 0;
	}
}