$form--checkbox: $greyAlto;
$form--checkbox-border: $greyAlto;
$form--checkbox-checked: $blueDenim;
$form--checkbox-focus: $blueBiscay;
$form--checkbox-size: 20px;

.#{$prefix}form__field-group--checkbox {
	.#{$prefix}form__focus-indicator {
		margin: 4px 3px;
	}
	&.has-focus .#{$prefix}form__focus-indicator {
		opacity: 0;
	}
}

.#{$prefix}form__checkbox {
	position: absolute;
	left: -999999em;

	& + .#{$prefix}form__label {
		display: block;
		cursor: pointer;
		padding: 0 0 0 30px;
		position: relative;
		font-weight: 400;
		text-transform: none;
		color: $text-colour;
		margin: 0 0 10px;

		// custom checkbox background
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: $form--checkbox-size;
			height: $form--checkbox-size;
			margin-right: 1em;
			text-align: center;
			color: $form--checkbox;
			background: $form--checkbox;
			border: 1px solid $form--checkbox-border;
			box-sizing: border-box;
			transition: border-color .25s ease;
			pointer-events: none;
		}

		// custom checkbox icon
		&:after {
			content: map-get($icon-defs,'tick');
			position: absolute;
			top: 3px;
			left: 3px;
			color: $form--checkbox-checked;
			display: block;
			@include font-size(15px);
			font-family: 'icons';
			font-style: normal;
			font-weight: normal;
			text-transform: none;
			line-height: 1;
			opacity: 0;
			pointer-events: none;
		}

		span {
			pointer-events: none;
		}

	}

	// checked
	&.is-checked {
		& + .#{$prefix}form__label {
			&:after {
				opacity: 1;
			}
		}
	}

	// focus
	&.has-focus {
		& + .#{$prefix}form__label {
			&:before {
				border-color: $form--checkbox-focus;
			}

			.#{$prefix}form__focus-indicator {
				opacity: 1;
			}
		}
	}

	// error
	.has-error & {
		& + .#{$prefix}form__label {
			&:before {
				background: $form--error-bkg;
				border-color: $form--error-border;
			}
		}
	}


	// disabled
	.is-disabled & {
		& + .#{$prefix}form__label {
			cursor: not-allowed;

			&:before {
				background: $form--disabled;
				border-color: $form--disabled;
				cursor: not-allowed;
			}
		}
	}

}








