
// ==========================================================================
//   Pagination
// ==========================================================================


/**
 * PLEASE READ ME
 * Base styles come from the button styles themselves.
 *
 * The class 'pagination--number' is used to identify the numbers
 * in the pagination so we can hide all except the active buttons
 * for screens less thatn 767px wide. The purpose of this is so that
 * pagination does not awkwardly wrap.
 *
 * The class 'pagination--is-active' is used to identify the active
 * page in the pagination, this overrides pagination--number and
 * shows the current active page you are on. These should be updated
 * via the templates, or alternativley js.
 */

.#{$prefix}pagination {
	margin: 0 0 2em;
	list-style: none;
	padding: 0;

	&__item {
		display: inline-block;
		margin: 0 0 0.2em;

		a {
			color: $text-colour;
			border: 2px solid $greyNurse;
			background: $greyNurse;
			font-size: 1.2em;
			text-decoration: none;
			display: block;
			padding: 0.2em 0.7em 0.3em;

			span[class^="#{$prefix}icon--"]:before {
				font-size: 0.7em;
				color: $greyDove;
			}

			span.sp-icon--double-chevron-right:before,
			span.sp-icon--double-chevron-left:before {
				font-size: 0.86em;
			}

			&:hover {
				color: #fff;
				border: 2px solid $blueDenim;
				background: $blueDenim;

				span[class^="#{$prefix}icon--"]:before {
					color: #fff;
				}
			}

			&:focus {
				color: $text-colour;
				border: 2px solid $greyDove;
				outline: none
			}

			&:active {
				color: #fff;
				border: 2px solid $blueTory;
				background: $blueTory;
			}
		}
	}
}


.#{$prefix}pagination--number {
	background: $greyNurse;
}


.#{$prefix}pagination--is-active {
	a {
		color: #fff;
		border: 2px solid $blueDenim;
		background: $blueDenim;

		&:focus {
			color: #fff;
			border: 2px solid $blueBiscay;
			background: $blueBiscay;
		}
	}
}


.#{$prefix}business-list-pagination {
	margin: 2em 0 2em;
	list-style: none;
	padding: 0;

	li {
		display: inline-block;
		margin: 0 0.1em 0.2em;
		background: $greyNurse;

		a {
			color: $text-colour;
			border: 2px solid $greyNurse;
			background: $greyNurse;
			font-size: 1.2em;
			text-decoration: none;
			display: block;
			padding: 0.2em 0.7em 0.3em;

			span[class^="#{$prefix}icon--"]:before {
				font-size: 0.7em;
				color: $greyDove;
			}

			span.sp-icon--double-chevron-right:before,
			span.sp-icon--double-chevron-left:before {
				font-size: 0.86em;
			}

			&:hover {
				color: #fff;
				border: 2px solid $blueDenim;
				background: $blueDenim;

				span[class^="#{$prefix}icon--"]:before {
					color: #fff;
				}
			}

			&:focus {
				color: $text-colour;
				border: 2px solid $greyDove;
				outline: none
			}

			&:active {
				color: #fff;
				border: 2px solid $blueTory;
				background: $blueTory;
			}
		}
	}

	li.active {
		a {
			color: #fff;
			border: 2px solid $blueDenim;
			background: $blueDenim;
	
			&:focus {
				color: #fff;
				border: 2px solid $blueBiscay;
				background: $blueBiscay;
			}
		}
	}
}