#mc_embed_signup_scroll {
	position: relative;
}

#mce-success-response {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	z-index: 10;
	text-align: left;
	font-size: 18px;
	color: $blueBiscay;
}