.#{$prefix}quote {
	@include font-size(24px);
	color: $blueBiscay;
	margin: 1.1em auto 1.3em;
	font-weight: 400;
	font-style: italic;
	position: relative;
}

// .#{$prefix}quote {
// 	color: $blueBiscay;
// 	max-width: 620px;
// 	margin: 0 auto;
// 	text-align: center;
// }

// .#{$prefix}quote {
// 	.#{$prefix}quote__icon,
// 	.#{$prefix}quote__text,
// 	.#{$prefix}quote__attribution {
// 		transition: transform 300ms ease-out, opacity 300ms ease-out;
// 		opacity: 0;
// 		transform: translate(0, 20%) scale(0.9);

// 		&.is-ready {
// 			opacity: 1;
// 			transform: translate(0, 0) scale(1);
// 		}
// 	}
// }

// .#{$prefix}quote__icon {
// 	display: block;
// 	margin: 0 auto 1em auto;
// 	fill: currentColor;
// }

// .#{$prefix}quote__text {
// 	@include font-size(34px);
// 	font-style: italic;
// 	margin-bottom: 1.25rem;
// 	font-family: inherit;
// }

// .#{$prefix}quote__attribution {
// 	@include font-size(20px);
// 	text-transform: uppercase;
// 	position: relative;
// 	padding: 1em 0;
// 	color: inherit;
// 	font-family: inherit;
// 	overflow: hidden;

// 	&:before {
// 		content: "";
// 		width: 90%;
// 		height: 1px;
// 		position: absolute;
// 		top: 0;
// 		left: 50%;
// 		transform: translate(-50%, 0);
// 		border-width: 2px;
// 		border-style: solid;
// 		border-color: inherit;
// 	}
// }
