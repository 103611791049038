.#{$prefix}related-content {

	&__item {
		color: $text-colour;
		background: $greyNurse2;
		border-bottom: 3px solid $greenJava;
		display: block;
		text-decoration: none;
		margin-bottom: 2em;

		&:hover,
		&:focus {
			img {
				transform: scale(1.05);
			}

			.sp-related-content__title {
				color: $blueDenim;
				text-decoration: underline;

				&:after {
					margin-left: 0.4em;
					color: $blueDenim;
					text-decoration: underline;
				}

				&:after {
					text-decoration: none;
				}
			}
		}
		&:visited{
			color:inherit;
		}
	}

	&__title {
		@include font-size(20px);
		color: $blueBiscay;
		text-decoration: none;
		transition: color 250ms ease-in-out;

		&:after {
			@extend .#{$prefix}icon;
			@include font-size(13px);
			line-height: 30px;
			padding: 0 0 0 10px;
			content: map-get($icon-defs, 'chevron-right');
			position: absolute;
			transition: $transition;
			margin-left: 0;
			color: $blueBiscay;
		}
	}

	&__content {
		width: 100%;
		padding: 1em;
		margin-left: 0;
		background: $greyNurse2;
		transition: all 400ms ease-in-out;

		@include bp(md) {
			padding: 1.2em 0;
			margin-left: -1em;
		}
	}

	&__image {
		display: none;

		@include bp(md) {
			display: block;
			overflow: hidden;
			margin: 0;

			img {
				width: 100%;
				transition: transform 400ms ease-in-out;
			}
		}
	}
}