
// ==========================================================================
//   Breadcrumbs
// ==========================================================================


/**
 * PLEASE READ ME
 * Base link styles are inherited from the standard link, this can be
 * overwritten here if necessary.
 *
 */

.#{$prefix}breadcrumb {
	margin: 1em 0;
	position: relative;

	&__icon {
		@include font-size(20px);
		text-decoration: none;
		color: $blueAzure;
		position: absolute;
		top: -3px;
		left: 0;
	}

	&__list {
		margin: 0;
		padding: 0 0 0 30px;
		list-style: none;
		position: relative;
	}

	&__item {
		@include font-size(14px);
		color: $greyDove;
		display: inline-block;
		line-height: 20px;

		+ .#{$prefix}breadcrumb__item:before  {
			@include font-size(10px);
			@extend .#{$prefix}icon;
			content: map-get($icon-defs,'chevron-right');
			margin: 0 .4em;
			line-height: 22px;
		}
	}
}