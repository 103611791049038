/* ==========================================================================
   Nav - Off Canvas
   ========================================================================== */
$nav--off-canvas-width: 240px;

.#{$prefix}nav--off-canvas {
	position: fixed;
	top: 0;
	left: -$nav--off-canvas-width;
	width: $nav--off-canvas-width;
	height: 100%;
	transition: $nav--transition;

	.#{$prefix}nav-active & {
		transform: translateX($nav--off-canvas-width);
	}

	@include bp($nav--breakpoint) {
		position: static;
		width: auto;

		.#{$prefix}nav-active & {
			transform: none;
		}
	}

	.#{$prefix}nav__button {
		position: absolute;
		top: 0;
		left: 100%;
	}

	.#{$prefix}nav__list {
		overflow-y: auto;
		height: 100%;
		display: block;

		@include bp($nav--breakpoint) {
			overflow: visible;
		}
	}

	.#{$prefix}nav__item {
		max-height: none;
	}
}