/* ==========================================================================
   Search Form
   ========================================================================== */

/**
 * PLEASE READ ME
 *
 * This includes styles for two types of search forms, the closed and
 * always open forms.
 *
 * Optimisation
 * Remove styles for the search box you are not using.
 *
 */

 $search-btn-size: 50px;

 $search-input--fill: #fff;
 $search-input--text: $greyShark;

 $search-btn--fill: $blueBiscay;
 $search-btn--text: #fff;


 .#{$prefix}search {
 	position: relative;
 	backface-visibility: hidden;
 	overflow: hidden;

 	.#{$prefix}icon--search {
 		@include font-size(15px);
 		color: $blueDenim;
 		position: absolute;
 		top: 0;
 		left: 0;
 		padding: 9px 11px;
 	}

 	&__search-clear {
 		position: absolute;
 		top: 8px;
 		bottom: 10px;
 		right: 10px;
 		width: 23px;
 		border-radius: 23px;
 		overflow: hidden;
 		color: lighten($greyDove,20%);
 		background: lighten($greyDove,20%);
 		transition: all 500ms ease-in-out;

 		&:hover,
 		&:focus {
 			color: $greyDove;
 			background: $greyDove;
 			outline: none;
 		}

 		&.is-hidden {
 			opacity: 0;

 			.#{$prefix}icon--close {
 				&:before,
 				&:after {
 					transform: rotate(360deg);
 				}
 			}
 		}

 		.#{$prefix}icon--close {
 			&:before,
 			&:after {
 				content: '';
 				width: 13px;
 				height: 3px;
 				background: #fff;
 				position: absolute;
 				top: 0;
 				left: 0;
 				bottom: 0;
 				right: 0;
 				margin: auto;
 				transition: transform 600ms ease-in-out;
 			}

 			&:before {
 				transform: rotate(45deg);
 			}

 			&:after {
 				transform: rotate(-45deg);
 			}
 		}
 	}
 }

 input.#{$prefix}search__input {
 	@include font-size(13px);
 	background: $search-input--fill;
 	color: $search-input--text;
 	display: inline-block;
 	margin: 0;
 	padding: 7px 90px 7px 33px;
 	font-family: inherit;
 	border-radius: 0;
 	-webkit-appearance: none;
 	width: 100%;
 	border: none;
 	outline: none;
 	transition: $transition;
 	line-height: 25px;
	height: 39px; // ie fix

	&:focus {
		color: $form--text-focus;
		background: $form--focus;
	}

	&::-webkit-input-placeholder {
		color: $form--placeholder;
	}
	&:-moz-placeholder {
		color: $form--placeholder;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: $form--placeholder;
	}
	&:-ms-input-placeholder {
		color: $form--placeholder;
	}

	@if $ie8support == true {
		.lt-ie9 & {
			padding-top: 0;
			padding-bottom: 0;
			line-height: $search-btn-size;
		}
	}

	// when site search placed in main content area
	.sp-main & {
		background: $form--focus;
		&:focus {
			color: darken($form--text-focus, 20);
			background: darken($form--focus, 5);
		}
	}
}

.#{$prefix}search__submit {
	@include font-size(15px);
	position: absolute;
	right: 0;
	top: 0;
	background: $search-btn--fill;
	color: $search-btn--text;
	display: inline-block;
	margin: 0;
	padding: 11px;
	width: 80px;
	cursor: pointer;
	transition: background 300ms linear;
	text-align: center;
	border: 1px solid $search-btn--fill;
	outline: none;
	line-height: 1;

	&:hover,
	&:focus {
		background: darken($search-btn--fill, 10%);
	}
}

.#{$prefix}search__search-clear {
	background: none;
	text-decoration: underline;

	&:hover,
	&:focus {
		text-decoration: none;
		outline: none;
	}
}

// jQuery UI Autocomplete overrides
.ui-menu {
	background: #fff;
	border: 1px solid #ccc;
	border-top: 0;
	padding: 0.5em 1em 0.5em 2em;
	list-style: none;

	.ui-menu-item {
		@include font-size(13px);
		padding: 0.3em 0;

		a:hover,
		a:focus {
			text-decoration: underline;
		}
	}
}

// Search Results Page
// overrides for google search widget
.#{$prefix}search-results {
	.gsc-control-cse {
		padding: 0;
		border: 0;
	}

	.gsc-control-cse,
	.gsc-control-cse .gsc-table-result {
		font-family: $font-default !important;
	}

	.gsc-control-cse .gs-spelling,
	.gsc-control-cse .gs-result .gs-title,
	.gsc-control-cse .gs-result .gs-title * {
		@include font-size(18px);
		color: $blueAzure;

		&:visited{
			color: $purpleGalaxy;
			b {
			color: $purpleGalaxy;
			}
		}

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			b {
				text-decoration: none;
			}
		}
	}

	.cse .gsc-webResult.gsc-result,
	.gsc-webResult.gsc-result,
	.gsc-imageResult-column,
	.gsc-imageResult-classic {
		padding: 0 !important;
		border: 0 !important;
		margin: 30px 0 0;
	}

	.gsc-result-info {
		padding: 0;
	}

	div.gs-fileFormat {
		margin: 0.5em 0 0;

		&:before {
			@extend .#{$prefix}icon;
			@include font-size(15px);
			content: "\e924";
			margin-right: 4px;
		}
	}

	span.gs-fileFormat,
	span.gs-fileFormatType {
		@include font-size(15px);
	}

	.gs-result a.gs-visibleUrl,
	.gs-result .gs-visibleUrl {
		color: $greyShuttle;
	}

	.gs-result .gs-snippet {
		@include font-size(15px);
		margin: 0.5em 0;
	}

	.gsc-results .gsc-cursor-box {
		margin: 3em 0 3em;
	}

	.gsc-results .gsc-cursor-box .gsc-cursor-page {
		@include font-size(18px);
		color: $text-colour;
		border: 2px solid $greyNurse;
		background: $greyNurse;
		text-decoration: none;
		display: inline-block;
		padding: 0.2em 0.7em 0.3em;
		font-weight: 400;
		transition: all 250ms;
		margin: 0 0.1em 0.2em;

		&:hover {
			color: #fff;
			border: 2px solid $blueDenim;
			background: $blueDenim;
		}

		&:focus {
			color: $text-colour;
			border: 2px solid $greyDove;
			outline: none
		}

		&:active {
			color: #fff;
			border: 2px solid $blueTory;
			background: $blueTory;
		}
	}

	.gsc-results .gsc-cursor-box .gsc-cursor-current-page {
		color: #fff;
		border: 2px solid $blueDenim;
		background: $blueDenim;
		font-weight: 400;

		&:focus {
			color: #fff;
			border: 2px solid $blueBiscay;
			background: $blueBiscay;
		}
	}

	.gs-no-results-result .gs-snippet,
	.gs-error-result .gs-snippet {
		background: none;
		padding: 0;
		border: 0;
		margin: 0;
	}

	.media {
		margin-bottom: 2em;
	}

	.media-body {
		h3 {
			margin-bottom: 5px;
		}
		p {
			margin-bottom: 0.5em;
		}
		a {
			display: block;
			width: 100%;
			overflow: hidden;
			word-break: break-all;
		}
	}

	.#{$prefix}pagination {
		margin-top: 1em;
	}
}
