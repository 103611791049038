.#{$prefix}form__field-group--file {
	&.has-focus .#{$prefix}form__focus-indicator {
		opacity: 0;
	}
}

.#{$prefix}form__file {
	display: block;
	width: 100%;
	background: $form--fill;
	padding: 0.7em 0.4em 0.8em 0.4em;
	border: 1px solid $form--border;

	// If it is an inline form change the layout a bit
	.#{$prefix}form--inline & {
		@include bp(md) {
			display: inline-block;
			width: auto;
		}
	}

	&:focus {
		outline: none;
		border: 1px solid $blueBiscay;

		.has-error & {
			border: 1px solid $form--error-border;
		}
	}

	&-wrap {
		position: relative;
		margin: 0 0 1em;

		&.has-focus {
			.#{$prefix}form__focus-indicator {
				opacity: 1;
			}
		}
	}

	&-remove {
		position: absolute;
		top: 10px;
		bottom: 10px;
		right: 10px;
		width: 25px;
		height: 25px;
		border-radius: 25px;
		overflow: hidden;
		color: lighten($greyDove,20%);
		background: lighten($greyDove,20%);
		transition: all 500ms ease-in-out;

		&:hover,
		&:focus {
			color: $form--error-border;
			background: $form--error-border;
			outline: none;
		}
	}
}

.#{$prefix}icon--remove {
	&:before,
	&:after {
		content: '';
		width: 13px;
		height: 3px;
		background: #fff;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		transition: transform 600ms ease-in-out;
	}
}

.#{$prefix}icon--remove {
	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}