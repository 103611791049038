.#{$prefix}block--info {
	background: $greyNurse2;
	padding: 1.5em;
	margin: 0 0 3em;

	[class*=#{$prefix}icon--] {
		color: $blueBiscay;
	}

	&__links {
		margin-top: 2em;
		margin-bottom: 1em;
		text-align: center;

		.#{$prefix}btn--block {
			max-width: 240px;
		}
	}
}

.#{$prefix}info-details {
	margin-left: 1em;
	list-style: none;

	&:after {
	    content: "";
	    display: table;
	    clear: both;
	}

	&__item {
		margin-left: 0;
		display: block;
		vertical-align: top;
		padding-bottom: 1em;
		clear: both;
	}

	&__label {
		font-weight: bold;
		text-transform: uppercase;
		@include font-size(13px);
		float: left;
		width: 28px;
		margin: 7px 0 1em -36px;
		line-height: 9px;
		clear: left;
		color: $blueBiscay;

		[class*=#{$prefix}icon--] {
			@include font-size(17px);
		}

		.cost &,
		.hosted &,
		.extras & {
			[class*=#{$prefix}icon--] {
				@include font-size(12px);
				border: 1px solid #1d3d71;
				border-radius: 12px;
				width: 20px;
				height: 20px;
				display: block;
				text-align: center;
				line-height: 19px;
				margin-top: -4px;
			}
		}
	}

	&__detail {
		float: none;
		margin: 0;
		padding: 0;
		line-height: 22px;

		strong {
			@include font-size(14px);
			color: $blueBiscay;
			text-transform: uppercase;
		}
	}

	&--inline {
		margin: 0;
		padding-left: 3px;

		.#{$prefix}info-details {
			margin-left: 0;

			&__item {
				display: inline-block;
				white-space: nowrap;
				margin: 0;
				padding-bottom: 0;
				padding-right: 10px;
			}

			&__label {
				display: inline-block;
				margin: 0;
				float: none;
				clear: none;
				vertical-align: top;
				margin-top: 24px;

				[class*=#{$prefix}icon--] {
					display: inline-block;
				}
			}

			&__detail {
				display: inline-block;
				float: none;
				padding: 18px 25px 0 0;
				white-space: normal;
			}

			&__phone {
				@include font-size(15px);
			}
		}
	}
}
