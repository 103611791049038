/* ==========================================================================
   The Grid Styles
   ========================================================================== */

/**
 * PLEASE READ ME
 *
 * The grid works like standard grids with sm, md, lg options for breakpoints
 *
 * Modification classes:
 * .grid__nofloat -- This removes the float e.g. allows centering with margins
 * .grid-gutter -- optional outside grid padding.
 * .grid--show-bg  -- This will add a background, border etc to demo the grid.
 *
 * Example classes:
 *
 * .grid > .grid__col-12
 * .grid > .grid__col-xs-12
 *
 */


$gutter: 15px;
$gutterNarrow: 7px;
$cols: 12;

[class*='grid__col-'] {
	float: left;
	width: 100%;
	padding: 0 $gutter;
	box-sizing: border-box;
}

.#{$prefix}grid {
	margin: 0 (-$gutter);
	// position: relative;

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	&__col {
		@for $i from 1 to $cols {
			&-#{$i} {
				width: percentage($i / $cols);
			}
		}

		@include bp(xs) {
			&-xs {
				@for $i from 1 to $cols {
					&-#{$i} {
						width: percentage($i / $cols);
					}
				}
			}
		}


		@include bp(sm) {
			&-sm {
				@for $i from 1 to $cols {
					&-#{$i} {
						width: percentage($i / $cols);
					}
				}
			}
		}


		@include bp(md) {
			&-md {
				@for $i from 1 to $cols {
					&-#{$i} {
						width: percentage($i / $cols);
					}
				}
			}
		}


		@include bp(lg) {
			&-lg {
				@for $i from 1 to $cols {
					&-#{$i} {
						width: percentage($i / $cols);
					}
				}
			}
		}
	}

	/* Opt-in outside padding */
	&--gutter {
		margin: 0;
		padding: 0 $gutter;
	}

	// Uncomment this and apply to grid if you want to visualise your grid
	// &--show {
	// 	background: #bbb;
	// 	margin: 0;
	// 	padding: 0 $gutter;
	//
	// 	div {
	// 		position: relative;
	// 		padding-bottom: 6em;
	//
	// 		&:after {
	// 			background: #eee;
	// 			content: '';
	// 			position: absolute;
	// 			top: 2em;
	// 			bottom: 2em;
	// 			left: $gutter;
	// 			right: $gutter;
	// 		}
	// 	}
	// }
}

// Class to tighten up spacing in sp-grid - used on forms
.#{$prefix}grid--narrow {
	margin: 0 (-$gutterNarrow);

	[class*='grid__col-'] {
		padding: 0 $gutterNarrow;
	}

	.#{$prefix}grid & {
		margin: 0 (-$gutterNarrow);
	}
}
