.#{$prefix}event {
	//needed for absolute positioning
	//of Event Information
	position: relative;

	&__categories {
		margin-bottom: 3em;
		@extend .#{$prefix}clearfix;

		.#{$prefix}form__field-group {
			float: left;
			width: auto;
			margin-right: 0.5em;

			&.has-focus .#{$prefix}form__label {
				border: 2px solid $blueDenim;
			}
		}

		.#{$prefix}form__label {
			margin: 0;
			border: 2px solid #fff;
			background: $greyNurse;
			padding: 13px 18px 13px 42px;
			cursor: pointer;
			transition: border-color 300ms ease-in-out;

			&:before {
				background: #fff;
				border: 1px solid $greyNurse;
				top: 13px;
				left: 13px;
			}

			&:after {
				top: 16px;
				left: 16px;
			}

			span {
				width: auto;
			}
		}

		.#{$prefix}form__btn-group {
			float: none;

			@include bp(xs) {
				float: left;
			}

			button {
				transition: $transition;
			}
		}

		.#{$prefix}btn--secondary {
			border: 2px solid #fff;
		}
	}

	&__listing {
		.#{$prefix}event__meta {
			margin-bottom: 0.3em;
		}

		.#{$prefix}event__text {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	&__item {
		display: block;
		padding: 0 0 2.8em;
		text-align: left;
		clear: both;
	}

	&__image {
		display: none;

		@include bp(md) {
			display: block;

			img {
				width: 100%;
				transition: transform 400ms ease-in-out;
			}
		}
	}

	&__content {
		a {
			text-decoration: none;
		}
	}

	&__title {
		@include font-size(20px);
		color: $blueBiscay;
		text-decoration: none;
		transition: $transition;

		&:after {
			@extend .#{$prefix}icon;
			@include font-size(13px);
			line-height: 30px;
			padding: 0 0 0 10px;
			content: map-get($icon-defs, 'chevron-right');
			position: absolute;
			transition: $transition;
			margin-left: 0;
			color: $blueBiscay;
		}

		&:hover,
		&:focus,
		&:active {
			color: $blueDenim;
			text-decoration: underline;

			&:after {
				margin-left: 0.4em;
				color: $blueDenim;
				text-decoration: none;
			}
		}
	}

	&__meta {
		@include font-size(14px);
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 0 0.7em;
	}

	&__location  {
		color: $blueDenim;
		text-decoration: none;

		&:after {
			content: '|';
			color: $text-colour;
			display: inline-block;
			padding: 0 3px 0 7px;
		}
	}

	&__date {
		@include font-size(14px);
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 0 0.7em;
	}

	&__text {
		display: block;
		padding-top: 1em;
		padding-bottom: 1em;
	}

	&__tags {
		@include font-size(13px);
		display: block;
		text-transform: uppercase;
		display: inline-block;
		margin: -2px 3px 0 24px;

		.#{$prefix}icon--tag {
			@include font-size(13px);
			color: $blueDenim;
			margin-left: -23px;
    		margin-right: 6px;
		}

		@include bp(md) {
			.#{$prefix}icon--tag {
				@include font-size(15px);
				margin-right: 5px;
			}
		}
	}

	.#{$prefix}event > &__tags {
		margin-bottom: 2em;
	}

	&__tag {
		text-decoration: none;

		&:after {
			content: '•';
			display: inline-block;
			padding: 0 3px 0 7px;
		}

		&:last-child:after {
			content: '';
		}
	}

	&__info {
		position: relative;
		padding: 0;

		@include bp(md) {
			position: absolute;
			right: -53%;
			top: 0;
			padding: 0 0 0 10px;
		}

		// overrides for Info Details Block
		.#{$prefix}info-details__item {
			width: 100%;

			@include bp(xs) {
				width: 49%;
			}

			@include bp(sm) {
				width: 33%;
			}

			@include bp(md) {
				width: 100%;
			}

		}


	}

	.#{$prefix}block--info__links {
		margin-bottom: -1em;
	}

	&__links {
		margin-bottom: 2em;
	}

	.#{$prefix}alert__description {
		font-weight: bold;
		text-transform: uppercase;
	}
}