.#{$prefix}block--social {
	margin: 0 0 3em;

	&__title {
		@include font-size(14px);
		text-transform: uppercase;
		color: $blueAzure;
		font-weight: 600;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		margin: 0 5px 0 0;
		padding: 0;
		display: inline-block;

		a {
			display: block;
			transition: color 300ms ease-in-out,
						transform 300ms ease-in-out;

			&:hover,
			&:focus {
				transform: scale(1.05);
			}
		}

		&.facebook a {
			color: #46529c;

			&:hover,
			&:focus {
				color: darken(#46529c,10%);
			}
		}

		&.twitter a {
			color: #60a9dc;

			&:hover,
			&:focus  {
				color: darken(#60a9dc,10%);
			}
		}

		&.linkedin a {
			color: #017cb7;

			&:hover,
			&:focus  {
				color: darken(#017cb7,10%);
			}
		}

		&.email a {
			color: #1d3d71;

			&:hover,
			&:focus  {
				color: darken(#1d3d71,10%);
			}
		}
	}

	&__link {
		@include font-size(44px);
		text-decoration: none;
		color: inherit;
		cursor: pointer;
		background: none;
	}
}