/* ==========================================================================
   Accordion
   ========================================================================== */
$accordion--background: $greyNurse2;
$accordion--text: $text-colour;
$accordion--header-background: $greyNurse2;
$accordion--header-text: $blueDenim;
$accordion--padding: 20px;
$accordion--transition: 250ms ease-in-out;

.#{$prefix}accordion {
	color: $accordion--text;
	margin: 0 0 3em;
}

.#{$prefix}accordion__header {
	@include font-size(19px);
	font-weight: 700;
	line-height: 1;
	position: relative;
 	border-bottom: 3px solid $greyNurse;
	padding: 20px 35px 20px 20px;
	text-decoration: none;
	color: $accordion--header-text;
	background: $accordion--header-background;
	transition: all $accordion--transition;
	display: block;
	position: relative;

	&:after,
	&:before {
		content: '';
		position: absolute;
		top: 28px;
		right: $accordion--padding;
		transition: opacity $accordion--transition,
					transform 600ms ease-in-out;
	}

	&:before {
		width: 3px;
		border-bottom: 15px solid $accordion--header-text;
		margin-top: -6px;
	}

	&:after {
		width: 15px;
		border-bottom: 3px solid $accordion--header-text;
		margin-right: -6px;
	}

	&.is-active {
		border-bottom-color: $accordion--background;
		background-color: $accordion--background;

		&:before {
			transform: rotate(270deg);

			.no-csstransforms & {
			 	opacity: 0;
			}
		}

		&:after {
			transform: rotate(360deg);
		}
	}

	h3,
	h4 {
		color: inherit;
	}

	&:focus {
		outline: none;
		background: $blueBiscay;
		color: #fff;

		&:before,
		&:after {
			border-color: #fff;
		}
	}

	span[class^="#{$prefix}icon--"]:before {
		font-size: 0.85em;
		padding-right: 0.4em;
	}
}

.#{$prefix}accordion__panel {
	display: none;
	padding: $accordion--padding;
	padding-top: 0;
	border-bottom: 3px solid $blueDenim;
	background: $accordion--background;

	&:focus {
		outline: none;
	}

	@include editorOnly() {
		display: block !important;
	}
}