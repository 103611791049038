


.#{$prefix}form__select {
	display: block;
	width: 100%;
	background: $form--fill;
	padding: 0.7em 0.4em 0.8em 0.4em;
	border: 1px solid $form--border;

	//If it is an inline form change the layout a bit
	.#{$prefix}form--inline & {
		@include bp(md) {
			display: inline-block;
			width: auto;
		}
	}
}

//  hide select field until multi-select rendered
.#{$prefix}form__select-multi {
	display: none;
}

/* Custom Select Boxes -------------------------- */
.custom-select-container {
	display: block;
	width: 100%;

	//If it is an inline form change the layout a bit
	.#{$prefix}form--inline & {
		@include bp(md) {
			display: inline-block;
			width: auto;
		}
	}
}

.#{$prefix}form--inline .select {
	display: inline-block;
	width: auto;
}

.custom-select-container {
	position: relative;
}

// Set the dimensions of the replaced select element and make it transparent.
.custom.replaced {
	line-height: 2;
	position: relative;
	z-index: 10;
	width: 100%;
	height: $select-height;
	cursor: pointer;
	opacity: 0;
	border: 0;
	filter: alpha(opacity=0);
}

.custom-select {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	display: block;
	width: 100%;

	> span {
		line-height: $select-height;
		display: block;
		padding: 0 1em 0 1em;
		color: $form--text;
		background: $form--fill;
		border: 1px solid $form--border;
		transition: $transition;

		&:after {
			@include font-size(13px);
			font-family: 'icons';
			position: absolute;
			top: 0;
			right: 0;
			content: map-get($icon-defs,'chevron-down');
			line-height: $select-height;
			height: $select-height;
			width: $select-height;
			text-align: center;
		}
	}
}


.custom-select span span {
	display: block;
	overflow: hidden;
	height: $select-height;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// Focus state
.custom-select.active > span {
	color: $form--text-focus;
	background-color: $form--focus;
	border: 1px solid $form--border-focus;
}

// Error state
.has-error select ~ span.custom-select > span {
	background: $form--error-bkg;
	border: 1px solid $form--error-border;

	&:focus {
		border: 1px solid $form--error-border;
	}
}