.#{$prefix}document {
	position: relative;
	color: $greyShark;

	&__item {
		display: block;
		text-align: left;
		clear: both;
		padding-bottom: 10px;
		padding-left: 2em;
	}

	&__title {
		font-size: 1em;
		color: $blueDenim;
		margin-left: 24px;
		display: inline;
		line-height: 1.4em;
		margin-bottom: 0.8em;

		&:before {
			@extend .#{$prefix}icon;
			@include font-size(20px);
			font-weight: bold;
			display: inline-block;
			padding: 0 0.3em 0 0;
			margin-left: -50px;
			content: map-get($icon-defs, 'document');
			position: relative;
		}

		&:hover,
		&:focus,
		&:active {
			color: $blueDenim;
			text-decoration: underline;
		}

         &:visited{
			color:$purpleGalaxy;
		}
	}

	&__tags {
		@include font-size(13px);
		text-transform: uppercase;
		display: inline-block;
		margin: 1em 3px 0;

		.#{$prefix}icon--tag {
			@include font-size(13px);
			color: $yellowEnergy;
			margin-left: 0;
			margin-right: 6px;
		}

		@include bp(md) {
			.#{$prefix}icon--tag {
				@include font-size(15px);
				margin-right: 5px;
			}
		}
	}

	// Remove excess paddingwhen there's no description
	a + &__tags {
		margin-top: -0.5em;
		display: block;
	}

	&__tag {
		font-weight: bold;

		&:after {
			content: '•';
			display: inline-block;
			padding: 0 3px 0 7px;
		}

		&:last-child:after {
			content: '|';
		}
	}

	&__size {
		@include font-size(13px);
		display: inline;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
		color: $greyDove;
        
        &:visited{
			color:inherit;
		}
	}

	&__date {
		@include font-size(14px);
		font-weight: 700;
		text-transform: uppercase;
	}

	&--listing {
		padding: 1em 0 2em;

		.#{$prefix}document__item {
			padding-bottom: 2em;
			padding-left: 0;
		}

		.#{$prefix}document__title {
			display: block;

			&:before {
				margin-left: -23px;
			}
		}


	}

	&__filter {
		.#{$prefix}grid {
			margin: 0;
		}
	}

}

.#{$prefix}latest-edition {
	.#{$prefix}block--image,
	.#{$prefix}document__item {
		margin-bottom: 1em;
	}

	p.#{$prefix}document__date {
		margin: 0;
	}

	.#{$prefix}document__text {
		margin-top: 0;
	}
}