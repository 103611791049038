.external-link {
  .sp-whats-on__title,
  .sp-event__title {
    &::after {
      font-family: "icons";
      content: "\e902";
      font-weight: bold;
      padding: 0;
      padding-left: 0.6em;
    }
  }
  .sp-whats-on__title {
    &::after {
      padding-top: 0.45em;
    }
  }
}
