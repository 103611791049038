.sfPageWrapper {

	.#{$prefix}main {
		margin: 0;
	}

	.#{$prefix}footer {
		position: static;
		z-index: 1;
	}
}

.sfPageContainer {
	overflow-x: hidden;
}