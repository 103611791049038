
.#{$prefix}councillor {
	border-top: 3px solid $greyAlto;
	padding-bottom: 2em;
	padding-top: 2em;
	position: relative;

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	&__content {
		min-height: 240px;

		@include bp(xs) {
			padding-left: 170px;
		}

		@include bp(sm) {
			padding-left: 210px;
		}
	}

	&__image {
		display: none;

		@include bp(xs) {
			display: block;
			width: 140px;
			position: absolute;
			top: 2.2em;
			left: 0;
			margin: 0;
		}

		@include bp(sm) {
			width: 180px;
		}
	}

	&__too-end {
		font-weight: bold;
		text-transform: uppercase;
		margin: 0.4em 0 0.3em;

		@include bp(sm) {
			margin: 1em 0 0.3em;
		}
	}

	//  contact block overrides
	.#{$prefix}contact-details {

		&__label {
			[class*=#{$prefix}icon--] {
				@include font-size(18px);
			}
		}

		&--inline {
			margin-left: -19px;

			@include bp(sm) {
				margin-left: -22px;
			}

			.#{$prefix}contact-details {
				&__item {
					display: block;
					margin: 0 5px 0 -20px;

					@include bp(sm) {
						display: inline-block;
					}

					&:before {
						position: relative;
					}
				}

				&__label {
					margin-top: 6px;

					.#{$prefix}icon--mobile {
						margin-left: 3px;
					}
				}

				&__detail {
					padding: 0 25px 10px 10px;

					@include bp(sm) {
						padding: 0 25px 0 0;
					}

					a {
						color: $blueDenim;
					}
				}
			}
		}
	}

	&--mayor {
		border-top: 0;
		padding-top: 0;
		margin-bottom: 1em;

		.#{$prefix}councillor__image {
			width: 130px;
			float: right;
			display: block;
			margin-left: 20px;
			margin-bottom: 20px;
			margin-bottom: 6px;

			@include bp(xs) {
				width: 140px;
				position: absolute;
				top: 0;
				left: 0;
				margin: 0;
			}

			@include bp(sm) {
				width: 180px;
			}
		}

		.#{$prefix}councillor__content {
			@include bp(xs) {
				padding-left: 170px;
			}

			@include bp(sm) {
				padding-left: 210px;
			}
		}

		.#{$prefix}contact-details {
			&--inline {
				margin-left: -20px;
			}
		}
	}
}

// Remove spacing whan ward heading is added
.#{$prefix}block--content + .#{$prefix}councillor {
	border-top: 0;
	margin-top: -1em;

	&--mayor {
		margin-top: 1em;
	}
}

// Adding blue bottom border to signify end of ward listing
.#{$prefix}councillor + .#{$prefix}block--content {
	border-top: 3px solid $blueDenim;
	padding-top: 2em;
	z-index: 5;
}

// Adding blue bottom border to last councillor
.#{$prefix}councillor:last-child {
	border-bottom: 3px solid $blueDenim;
}