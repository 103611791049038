.#{$prefix}sitemap {
	list-style: none;

	a {
		text-decoration: none;
	}

	li {
		list-style: none;
		@include font-size(18px);
		font-weight: bold;
		display: none;

		&:first-child {
			display: block;
		}
	}

	&--l1 {
		padding-left: 0;
		li {
			margin-bottom: 2em;

			width: 100%;
			float: none;
			display: block;

			@include bp(xs) {
				float: left;
				width: 50%;

				&:nth-child(odd) {
					clear: both;
				}
			}

			@include bp(md) {
				width: 33%;

				&:nth-child(odd) {
					clear: none;
				}

				&:nth-child(4) {
					clear: both;
				}
			}


		}
	}

	&--l2 {
		margin-bottom: 1em;

		li {
			@include font-size(15px);
			font-weight: normal;
			margin-bottom: 0;
			border: 0;
			width: 100%;
			float: none;
			display: block;
		}
	}
}