.#{$prefix}block--image {
	text-align: center;
	margin: 0 0 3em;

	&__image {
		@include bp(sm) {
			display: inline-block;
			width: 100%;
		}
	}

	&__link {
		display: block;
		overflow: hidden;
		margin: 0 auto;

		@include bp(sm) {
			display: inline-block;
			width: 100%;
		}

		&:hover,
		&:focus {
			img {
				transform: scale(1.05);
			}
		}
	}

	img {
		max-width: 100%;
		transition: transform 400ms ease-in-out;
		display: block;
		margin: 0 auto;
	}

	&__caption {
		text-align: left;
		margin: 10px 0 0;
	}
}