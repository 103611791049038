
.#{$prefix}form__field-group--radio {
	@extend .#{$prefix}form__field-group--checkbox;
}

.#{$prefix}form__radio {
	@extend .#{$prefix}form__checkbox;

	& + .#{$prefix}form__label {
		&:before {
			border: 1px solid darken($greyAlto, 10);
			border-radius: 0.789em;
		}

		&:after {
			content: '';
			background: transparent;
			border: 6px solid $form--checkbox-checked;
			border-radius: 6px;
			top: 4px;
			left: 4px;
		}
	}
}