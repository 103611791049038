.#{$prefix}results__item {
	padding: 2em 0;
	border-bottom: 1px solid #000;

	&:last-child {
		border: 0;
	}
}

.#{$prefix}results__title {
	margin: 0 0 1em 0;
}