.#{$prefix}latest-news {
	background: $greyNurse;
	padding: 2.5em 1em 1em;
	position: relative;
	color: $greyShark;
	margin: 0 -1em;

	@include bp(md) {
		padding: 4em 1em 3em;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		margin: 0 0 0 -25px;
		border-top: 25px solid #fff;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
	}

	h2 {
		text-align: center;
		text-transform: uppercase;
		color: $blueDenim;
		margin: 0 0 1em;

		@include bp(md) {
			margin-bottom: 2em;
		}
	}

	&__listing {
		text-align: center;
		max-height: 60px;
		overflow: hidden;

		@include bp(md) {
			display: block;
			background: #fff;
			border-bottom: 3px solid $yellowEnergy;
			padding: 1.5em 1.5em 90px;
			min-height: 766px;
			max-height: none;
			text-overflow: ellipsis;
			position: relative;
		}

		.#{$prefix}latest-news__item {
			opacity: 0;

			@include bp(md) {
				opacity: 1;
			}
		}

		&--featured {
			display: block;
			background: none;
			padding: 0;
			border: none;
			text-align: center;

			.#{$prefix}latest-news {
				&__item {
					text-align: left;
					display: block;
					margin: 0 0 2em;
					border-top: none;
					position: relative;
					color: $text-colour;
					border-bottom: 3px solid $yellowEnergy;
					text-decoration: none;

					&:hover,
					&:focus {
						color: $greyShark;

						.#{$prefix}latest-news {
							&__text,
							&__tags {
								opacity: 1;
							}

							&__content {
								max-height: 100%;
							}

							&__title {
								color: $blueDenim;
								text-decoration: underline;

								&:after {
									color: $blueDenim;
									margin-left: 0.4em;
									text-decoration: none;
								}
							}
						}
					}

					@include bp(md) {
						margin-bottom: 2em;
					}
				}

				&__text,
				&__tags {
					@include bp(md) {
						opacity: 0;
						transition: opacity 250ms ease-in-out;
						max-height: none;
					}
				}

				&__title {
					color: $blueBiscay;
					text-decoration: none;
					line-height: 1.1em;
					transition: color 250ms ease-in-out;
					position: relative;

					@include bp(md) {
						line-height: 1.2em;
					}

					&:after {
						@extend .#{$prefix}icon;
						@include font-size(13px);
						color: $blueBiscay;
						padding: 0.16em 0 0 0.3em;
						content: map-get($icon-defs, 'chevron-right');
						position:  absolute;
						margin-left: 0;
						transition: $transition;
					}
				}
			}

			.#{$prefix}btn {
				@include bp(md) {
					display: none;
				}
			}
		}
	}

	&__item {
		display: none;
		padding: 1.5em 0 0;
		margin: 1.5em 0 0;
		border-top: 2px solid $greyNurse;
		text-align: left;

		@include bp(md) {
			display: block;
			position: relative;
		}

		&:first-child {
			border-top: none;
			padding: 0;
			margin: 0 0 1.4em;

			@include bp(md) {
				margin-bottom: 2em;
			}
		}
	}

	&__image {
		display: none;

		@include bp(md) {
			display: block;
			overflow: hidden;
			margin: 0 0 9em;

			img {
				width: 100%;
				transition: transform 400ms ease-in-out;
			}
		}
	}

	&__content {
		padding:  1.5em;
		background: #fff;
		width: 100%;

		@include bp(md) {
			position: absolute;
			bottom: 0;
			max-height: 9em;
			overflow: hidden;
			transition: all 400ms ease-in-out;
		}
	}

	&__title {
		@include font-size(20px);

		a {
			color: $blueBiscay;
			text-decoration: none;
			position: relative;

			span:after {
				@extend .#{$prefix}icon;
				@include font-size(13px);
				padding: 0.5em 0 0 0.3em;
				color: $blueBiscay;
				content: map-get($icon-defs, 'chevron-right');
				position: absolute;
				transition: $transition;
			}

			&:hover,
			&:focus,
			&:active {
				color: $blueDenim;
				text-decoration: underline;

				span:after {
					color: $blueDenim;
					margin-left: 0.4em;
					text-decoration: underline;
				}

				span:after {
					text-decoration: none;
				}
			}
		}
	}

	&__text {
		display: none;

		@include bp(md) {
			display: block;
			max-height: 4.3em;
			overflow: hidden;
		}
	}

	&__ellipsis {
		position: absolute;
		right: 0;
		bottom: 2px;
		width: 50px;
		text-align: center;
		background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%, #fff 30%, #fff 100%);
	}


	&__meta {
		@include font-size(14px);
		font-weight: 700;
		text-transform: uppercase;
		// margin: 0 0 0.7em;
	}

	&__type {
		color: $blueDenim;
		text-decoration: none;

		&:after {
			content: '|';
			color: $text-colour;
			display: inline-block;
			padding: 0 3px 0 7px;
		}
	}

	// &__date {}

	&__tags {
		@include font-size(13px);
		display: none;
		margin: -2px 3px 0 24px;

		@include bp(md) {
			display: block;
			text-transform: uppercase;

			.#{$prefix}icon--tag {
				@include font-size(15px);
				color: $yellowEnergy;
				display: inline-block;
				margin-left: -23px;
				margin-right: 6px;
			}
		}
	}

	@include bp(md) {
		&__tag {
			text-decoration: none;

			&:after {
				content: '•';
				display: inline-block;
				padding: 0 3px 0 7px;
			}

			&:last-child:after {
				content: '';
			}
		}
	}

	.#{$prefix}btn {
		@include bp(md) {
			position: absolute;
			bottom: 1.5em;
			left: 1.5em;
			right: 1.5em;
			margin: 0;
		}
	}
}