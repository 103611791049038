/* ==========================================================================
	 Responsive Tabs
	 ========================================================================== */
$responsive-tabs--border: 1px solid #ddd;
$responsive-tabs--background: #fff;
$responsive-tabs--highlight: #5C76DC;
$responsive-tabs--secondary-colour: #fafafa;
$responsive-tabs--text-colour: $text-colour;
$responsive-tabs--padding: 1.2em;

.#{$prefix}responsive-tabs {
	@include editorOnly() {
		&__header {
			display: block !important;
		}
		&__panel {
			display: block !important;
		}
	}
}

.#{$prefix}responsive-tabs {
	margin: 0 0 3em;

	&__toggle-links {
		display: none;

		&:before,
		&:after {
			content: " ";
			display: table;
		}

		&:after {
			clear: both;
		}
	}

	&__header {
		@include font-size(18px);
		font-weight: 700;
		line-height: 1;
		position: relative;
		border-bottom: 3px solid $greyNurse;
		padding: $accordion--padding;
		padding-right: $accordion--padding * 2;
		text-decoration: none;
		color: $accordion--header-text;
		background: $accordion--header-background;
		transition: all $accordion--transition;
		display: block;
		position: relative;

		&:after,
		&:before {
			content: '';
			position: absolute;
			top: 28px;
			right: $accordion--padding;
			transition: opacity $accordion--transition,
						transform 600ms ease-in-out;
		}

		&:before {
			width: 3px;
			border-bottom: 15px solid $accordion--header-text;
			margin-top: -6px;
		}

		&:after {
			width: 15px;
			border-bottom: 3px solid $accordion--header-text;
			margin-right: -6px;
		}

		&.is-active {
			border-bottom-color: $accordion--background;
			background-color: $accordion--background;

			&:before {
				transform: rotate(270deg);

				.no-csstransforms & {
					opacity: 0;
				}
			}

			&:after {
				transform: rotate(360deg);
			}
		}

		&:focus {
			outline: none;
			background: $blueBiscay;
			color: #fff;

			&:before,
			&:after {
				border-color: #fff;
			}
		}

		.#{$prefix}block--content {
			margin: 0;
		}
	}

	&__panel {
		display: none;
		padding: $accordion--padding;
		padding-top: 0;
		border-bottom: 3px solid $blueDenim;
		background: $accordion--background;
		transition: display 300ms linear;

		&:nth-child(2) {
			display: block;
		}

		// &.is-active {
		// 	display: block;
		// }

		&:focus {
			outline: none;
		}
	}

}

@mixin tab-styles {

	.#{$prefix}responsive-tabs {
		&__toggle-links {
			display: block;
			list-style: none;
			margin: 0;
			padding: 0;
			border-bottom: 3px solid $blueDenim;
		}

		&__tab {
			display: inline-block;
			background-color: $greyNurse2;
			position: relative;
			color: $greyShuttle;

			&.is-active {
				background-color: $blueDenim;
				color: #fff;
			}

			&:last-child .#{$prefix}responsive-tabs__toggle {
				border-right: 0;
			}

			.#{$prefix}block--content {
				margin: 0;
			}

		}

		&__toggle {
			@include font-size(14px);
			text-decoration: none;
			display: block;
			padding: 1em 1.6em;
			line-height: 1;
			color: inherit;
			font-weight: 700;
			text-transform: uppercase;
			border-right: 3px solid #fff;

			&:visited {
				color: inherit;
			}

			&:hover,
			&:focus {
				outline: none;
				background-color: $blueBiscay;
				color: #fff;
			}
		}

		&__content {
			padding: $responsive-tabs--padding 0;
			background: $responsive-tabs--background;
		}

		&__header {
			display: none;
		}

		&__panel {
			border: 0;
			background: none;
			padding: 0;
			margin: 0;
			height: auto !important;
			display: none !important;

			&.is-active {
				display: block !important;
			}
		}
	}
}

@include bp(sm) {
	@include tab-styles;

	.#{$prefix}responsive-tabs--three > ul > .#{$prefix}responsive-tabs__tab {
		width: 33.333%;
	}

	.#{$prefix}responsive-tabs--four > ul > .#{$prefix}responsive-tabs__tab {
		width: 25%;
	}

	.#{$prefix}responsive-tabs--five > ul > .#{$prefix}responsive-tabs__tab {
		width: 20%;
	}
}

.#{$prefix}responsive-tabs--two {
	@include tab-styles;

	& > ul > .#{$prefix}responsive-tabs__tab {
		width: 50%;
	}
}
