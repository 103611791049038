.#{$prefix}meetings {
	&.#{$prefix}responsive-tabs {
		margin-top: -1em;
	}

	//overright tabs widths
	&.#{$prefix}responsive-tabs--two>ul>.#{$prefix}responsive-tabs__tab {
		width: auto;
	}

	&.#{$prefix}responsive-tabs--two .#{$prefix}responsive-tabs__toggle {
		padding: 1em 0.8em;

		@include bp(xs) {
			padding: 1em 1.6em;
		}
	}

	&__table {
		th {
			background: $blueBotticelli;
			color: $blueBiscay;
			text-transform: uppercase;
			font-weight: 400;
			text-align: left;
			vertical-align: top;
		}

		tbody {
			tr {
				border-bottom: 2px solid $greyAlto !important; // tablesaw override
				display: block;
				padding: 10px 0;

				@include bp(table) {
					border-bottom: 0 !important; // tablesaw override
					display: table-row;
				}

				&:last-child {
					border-bottom: 3px solid $blueDenim !important;
				}

				&:nth-child(2n) {
					background: $greyNurse2;
				}
			}

			td {
				vertical-align: top;
				border-bottom: 0px;

				@include bp(table) {
					border-bottom: 2px solid $greyAlto;
				}

				.tablesaw-cell-label {
					color: $blueDenim;;
					display: block;
					width: auto;
					text-transform: uppercase;
					line-height: 1.6em;
				}

				.tablesaw-cell-content {
					width: 100%;
					max-width: 100%;
				}
			}
		}

		&--linked {
			margin-top: -15px;
			margin-bottom: -15px;

			@include bp(table) {
				margin: 0;
			}
		}
	}

	&__date {
		width: 10em;
		padding: 5px 15px !important; // tablesaw override

		@include bp(table) {
			padding: 15px 5px 15px 15px !important; // tablesaw override
		}
	}

	&__time {
		width: 4.5em;
		padding: 5px 15px !important; // tablesaw override

		@include bp(table) {
			padding: 15px 5px !important; // tablesaw override
		}
	}

	&__desc {
		padding: 5px 15px !important; // tablesaw override

		@include bp(table) {
			padding: 15px 15px 15px 5px !important; // tablesaw override
		}
	}

	&__documents {
		padding: 5px 15px !important; // tablesaw override

		@include bp(table) {
			width: 20em;
			padding: 15px 15px 15px 5px !important; // tablesaw override
		}

		.tablesaw-cell-content {
			@include font-size(14px);
		}

		&.empty {
			display: none;

			@include bp(table) {
				display: table-cell;
			}
		}
	}

	&__doc-item {
		position: relative;
		padding: 0.1em 0 0.6em 0;

		.#{$prefix}icon--document {
			position: absolute;
			top: 1px;

			&:before {
				@include font-size(12px);
				font-weight: 600;
				color: $blueDenim;
			}
		}

		&.sp-audio {
			&__container {
				padding: 0;		
				text-align: center;	
			}
		}
		
		.sp-audio {
			&__title {
				padding: 0;	
				color: $blueDenim;
			}
		}

		audio {
			width: 100%;
			display: block;
		}
	}

	&__doc-link {
		color: $blueDenim;
		margin-left: 20px;
		display: block;
	}



	//Table containing 2 or more rows of linked data
	&__linked-data {
		padding: 0px !important; // tablesaw override
		margin: 0;

		@include bp(table) {
			padding: 8px 0px !important; // tablesaw override
		}

		.tablesaw-cell-label:first-child {
			display: none;
		}

		.tablesaw-cell-content {
			width: 100%;
			max-width: 100%;
		}

		tbody {
			tr {
				border-bottom: 2px solid $greyAlto;

				@include bp(table) {
					border-bottom: 0 !important; // tablesaw override
				}

				&:nth-child(2n) {
					background: transparent;
					border-bottom: 0!important; // tablesaw override
				}

				&:last-child {
					border-bottom: 0;
				}
			}

			td {
				.tablesaw-cell-label:first-child {
					display: block;
				}

				.tablesaw-cell-label {
					color: $blueDenim;;
					display: block;
					width: 100%;
					text-transform: uppercase;
				}
			}
		}


		.#{$prefix}meetings__date,
		.#{$prefix}meetings__time,
		.#{$prefix}meetings__desc,
		.#{$prefix}meetings__documents {
			padding-top: 7px !important; // tablesaw override
			padding-bottom: 7px !important; // tablesaw override
			border-bottom: 0px !important; // tablesaw override

			@include bp(table) {
				padding-top: 7px !important; // tablesaw override
				padding-bottom: 7px !important; // tablesaw override
			}
		}
	}
}

.#{$prefix}meetings__table.tablesaw-stack tbody td {
	float: none;
}

#meetingsFilter .#{$prefix}form__sans-label {
	padding-top: 0.6em;

	@include bp(xs) {
		padding-top: 2.4em;
	}
}