.#{$prefix}body {
	padding: 120px 0 0;
	background-color: #fff;
	position: relative;

	@include bp(md) {
		padding: 0;
	}
}

.#{$prefix}container {
	max-width: 1170px;
	margin: 0 auto;
}

.#{$prefix}main {
	position: relative;
	padding: 0 1em 2em;
	z-index: 2;
	background: #fff;
}

.#{$prefix}background {
	display: none;

	@include bp(md) {
		display: block;
		overflow: hidden;
		position: fixed;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		z-index: -1;

		&__wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;
            clip: rect(0, auto, auto, 0);
			z-index: -1;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			min-width: 50%;
			min-height: 50%;
			margin: auto;
		}
	}
}

.sr-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}