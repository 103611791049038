
// Base styles
$baseFontSize: 16px !default;

// Flags
$ie8support: false;

$prefix: 'sp-';

// Sprites
$ieSprite: '.lt-ie9' !default;
$sprite: map-get($icons, sprite) !default;


// White and Black do not need variables.

$blueAzure: #336ab2;
$blueBaked: #80c4c9;
$blueBiscay: #1d3d71;
$blueBotticelli: #d7eaeb;
$blueChatham: #194475;
$blueDenim: #1478bb;
$blueScooter: #23b9d6;
$blueSinbad: #95d1d5;
$blueTory: #0c65a1;
$blueVenice: #0a5f98;

$greenAtlantis: #8cbe2e;
$greenChristi: #4dad0c;
$greenJava: #1fb5aa;

$greyAlto: #d9d9d9;
$greyDove: #737373;
$greyGallery: #f0f0f0;
$greyNurse2: #f4f5f4;
$greyNurse: #e9eae8;
$greyShark: #333;
$greyShuttle: #53666c;
$greySilver: #ccc;

$yellowEnergy: #f6de60;
$yellowLogo: #e59800;

$error: #e63505;
$success: $greenAtlantis;
$warning: #f4c00d;

$purpleGalaxy: #800080;

$text-colour: $greyShark;

// Fills!
// ------------------------------------------------
$fill--light: #fff;
$fill--med: $greySilver;
$fill--dark: #000;


// LINKS & BUTTONS
// ------------------------------------------------

// Base links
$link-colour: #000;
$link-colour--hover: #000;
$link-colour--focus: #000;
$link-colour--active: $greySilver;


// Animation
// ------------------------------------------------
$transition: all 250ms ease-in-out!default;


// Layout
// ------------------------------------------------
$global-margin: 1em;



// FORM STYLES
// ------------------------------------------------

// Error
$error: #e63505;


// BORDER STYLES
// ------------------------------------------------
$border--colour: #000;
$border--width: 3px;
$border--style: solid;

$border--radius: 24px !default;


// FONTS
// ------------------------------------------------
$font-fallback: arial, verdana, helvetica, sans-serif;
$font-default: 'Source Sans Pro', $font-fallback;


// Images
// ------------------------------------------------
// Define background images etc here
//$bg--body: images;



// Z-indexing
// ------------------------------------------------
$zindex: (
          searchInput: 10,
          searchIcon: 15,
          searchBtn: 20,
          clearBtn: 20
          );

//code example:
//.header {
//  z-index: map-get($zindex, header);
//}



// Breakpoints
// ------------------------------------------------

$breakpoints: (
               width: (
                       lg: 1200px,
                       md: 940px,
                       sm: 768px,
                       xs: 500px,
                       table: 640px
                       ),
               height: (
                        lg: 800px,
                        md: 600px,
                        sm: 520px
                        )
               );

//code example:
// .header {
//   @include bp(sm) {
//     display: none;
//   }
// }
// COMPILES TO ==>
// @media screen and (min-width: 768px) {
//   .header {
//     display: none
//   }
// }

// .header {
//   @include bp(500px, max) {
//     display: none;
//   }
// }
// COMPILES TO ==>
// @media screen and (max-width: 499px) {
//   .header {
//     display: none
//   }
// }