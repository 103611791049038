.#{$prefix}block--links {
	margin: 0 0 3em;
	background: $blueSinbad;
	padding: 1.5em;
	border-bottom: 3px solid $blueDenim;

	&__title {

	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		margin: 10px 0 15px;
		padding: 0 15px 0 0;
		min-width: 190px;
		white-space: nowrap;
		display: inline-block;
	}

	&__link {
		color: $blueBiscay;
		text-decoration: none;
		position: relative;
		display: inline-block;
		padding: 0 0 0 20px;

		&:before {
			@extend .#{$prefix}icon;
			@include font-size(12px);
			content: map-get($icon-defs, 'chevron-right');
			text-decoration: none;
			position: absolute;
			left: 0;
			top: 50%;
			margin: -7px 0 0;
			transition: left 200ms ease-in-out;
		}

		&:hover,
		&:focus {
			color: #000;

			&:before {
				left: 5px;
			}
		}
	}
}

.#{$prefix}quick-links {
	&__link {
		@include font-size(16px);
		line-height: 21px;
		display: block;
		background: $blueBiscay;
		color: #fff;
		margin: 0 0 0.3em;
		padding: 0.87em 1.2em;
		text-decoration: none;

		@include bp(lg) {
			padding: 1.13em 1.2em;
		}

		&:before {
			@extend .#{$prefix}icon;
			@include font-size(12px);
			content: map-get($icon-defs, 'chevron-right');
			margin: 0 0.8em 0 0;
			transition: $transition;
			display: inline-block;
		}

		&:visited {
			color: #fff;
		}

		&:hover,
		&:focus {
			background: $blueDenim;
			color: #fff;
			outline: none;
			// text-decoration: underline;

			&:before {
				margin: 0 0.5em 0 0.3em;
			}
		}
	}
}