/* ==========================================================================
   Gallery Styles
   ========================================================================== */

.#{$prefix}block--gallery {
	position: relative;
	margin: 0 0 3em;

	&__items {
		&.slick-slider {
			margin: 0 0 0.2em;
			background: $greyNurse2;
		}
		.slick-track {
			display: flex;
			align-items: stretch;
		}
	}

	&__item {
		position: relative;
		text-align: center;

		&.slick-slide.slick-slide {
			height: auto;
		}
	}

	&__caption {
		padding: 1.5em 5em 1.5em 1.5em;
		text-align: left;
		min-height: 66px;
		position: relative;

		@include bp(md) {
			background: rgba(#000,0.6);
			color: #fff;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	&__count {
		position: absolute;
		right: 0;
		top: 0;
		padding: 1.5em;
	}

	&__thumbnails.slick-slider {
		display: none;

		@include bp(md) {
			display: block;
			position: static;
			margin: 0 0 0.2em;

			.slick-track {
				display: flex;
				align-items: stretch;
			}

			&.less-than-6 {
				.slick-track {
					transform: none !important; // slick override
					margin: auto;
				}
			}
		}
	}

	&__thumbnail {
		border: 0.2em solid #fff;
		cursor: pointer;
		overflow: hidden;
		transition: $transition;

		&.slick-slide.slick-slide {
			height: auto;
			background: $greyNurse2;
		}

		img {
			transition: transform 400ms ease-in-out;
			display: block;
			max-height: 100%;
			width: auto;
		}

		&:hover,
		&:focus {
			outline: none;
			border: 0.2em solid $greyNurse;

			img {
				transform: scale(1.05);
			}
		}

		&.slick-current {
			border: 0.2em solid $blueDenim;
		}
	}

	.slick-arrow {
		width: auto;
		height: auto;
		top: 50%;
		margin: (-$slideshow-button-width / 2) 0 0;
		transform: none;

		&:before {
			@include font-size(16px);
			width: $slideshow-button-width;
			height: $slideshow-button-width;
			color: #fff;
			opacity: 1;
			line-height: 28px;
			background: rgba(#000,0.2);
			border: 3px solid #fff;
			border-radius: 100px;
			text-align: center;
			display: block;
		}

		&:hover,
		&:focus {
			&:before {
				background: rgba(#000,0.5);
			}
		}
	}

	.slick-prev {
		left: 20px;
		z-index: 1;

		&:before {
			padding-right: 3px;
		}
	}

	.slick-next {
		right: 20px;

		&:before {
			padding-left: 3px;
		}
	}
}