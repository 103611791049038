/* ==========================================================================
   Nav
   ========================================================================== */

$nav--bg: none;
$nav--hover-bg: none;
$nav--active-bg: none;
$nav--text-colour: $blueBiscay;
$nav--hover-colour: $blueDenim;
$nav--transition: $transition;
$nav--subnav-width: 200px;
$nav--padding: 15px;
$nav--standard-border: 1px solid #fff;
$nav--subnav-bg: #fff;
$nav--subnav-hover-colour: #fff;
$nav--subnav-hover-bg: #444;
$nav--breakpoint: "md";

.#{$prefix}nav--main {
  z-index: 10;
  text-align: left;

  @include bp($nav--breakpoint) {
    display: block;
    overflow: visible;
    width: auto;
    background: none;
    text-align: right;
  }

  &:after {
    .#{$prefix}nav-open & {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 120vh;
      background: rgba(0, 0, 0, 0.7);
      z-index: -1;
    }
  }

  .#{$prefix}container {
    display: block;
  }

  .#{$prefix}nav {
    &__list {
      z-index: 2;
      margin: 0;
      padding: 0;
      list-style: none;
      display: none;
      background: $blueDenim;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      max-height: calc(100vh - 150px);
      overflow-y: scroll;
      transition: max-height 250ms ease-in-out;
      border-bottom: 3em solid $nav--bg;

      @include bp($nav--breakpoint) {
        display: block !important;
        height: auto !important;
        background: $nav--bg;
        position: static;
        max-height: none;
        overflow-y: visible;
        margin: 0 (-$nav--padding);
        border-bottom: 0;
      }

      .#{$prefix}nav-active & {
        display: block;
      }
    }

    &__item {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      border-bottom: $nav--standard-border;

      @include bp($nav--breakpoint) {
        width: auto;
        border: 0;
        display: inline-block;
        padding: 0 0 50px;
        margin: 0 0 -40px;
      }

      &.has-children .#{$prefix}nav__link {
        &:before,
        &:after {
          transition: $nav--transition;
        }

        &:before {
          @include bp($nav--breakpoint) {
            content: "";
            background: #fff;
            position: absolute;
            left: 50%;
            right: 0;
            bottom: 0;
            width: 10px;
            height: 14px;
            margin: 0 0 -14px -5px;
          }
        }

        &:after {
          @extend .#{$prefix}icon;
          @include font-size(13px);
          content: map-get($icon-defs, "chevron-down");
          position: absolute;
          color: #fff;
          left: 100%;
          bottom: 50%;
          margin: 0 0 -9px -50px;
          text-align: center;
          width: 50px;
          transition: $nav--transition;
          text-decoration: none;

          @include bp($nav--breakpoint) {
            color: $nav--text-colour;
            content: map-get($icon-defs, "chevron-down");
            width: 30px;
            left: 50%;
            bottom: 0;
            margin: 0 0 -14px -15px;
          }
        }

        &:hover,
        &:focus {
          &:after {
            @include bp($nav--breakpoint) {
              color: $nav--hover-colour;
            }
          }
        }
      }

      &.has-children.is-active .#{$prefix}nav__link {
        color: #fff;

        @include bp($nav--breakpoint) {
          color: $nav--hover-colour;

          &:after {
            color: $nav--hover-colour;
          }
        }
      }

      &.is-open {
        .#{$prefix}nav__subnav {
          display: block;

          @include bp($nav--breakpoint) {
            display: block !important;
          }

          .#{$prefix}nav__subnav {
            display: none;

            @include bp($nav--breakpoint) {
              display: block !important;
            }
          }
        }

        &.has-children {
          .#{$prefix}nav__link:after {
            transform: scaleY(-1);

            @include bp($nav--breakpoint) {
              transform: none;
            }
          }
        }
      }

      @include bp($nav--breakpoint) {
        &:hover,
        &.has-focus {
          .#{$prefix}nav__link {
            border-bottom: 2px solid;
          }

          & > .#{$prefix}nav__subnav {
            display: block;
            z-index: 5;
            padding: 1.5em 0 3em 0;
            max-height: 500px;
            border-bottom: 10px solid $blueDenim;
            transition-delay: 400ms;
          }
        }

        &:hover {
          & > .#{$prefix}nav__subnav {
            z-index: 6;
          }
        }
      }

      &.is-active {
        font-weight: bold;

        @include bp($nav--breakpoint) {
          .#{$prefix}nav__link {
            border-bottom: 2px solid;
          }
        }
      }

      // client request to have 3rd level all begin in the same position
      // only works if none of the 2nd level drop to a third line.
      .has-children > .#{$prefix}nav__subnav__link {
        @include bp($nav--breakpoint) {
          display: table-cell;
          height: 45px;
          vertical-align: bottom;
          padding: 0;
        }
      }

      &.my-south-perth {
        margin: 20px;
        border: 2px solid #fff;
        width: auto;

        display: block;
        @include bp($nav--breakpoint) {
          display: none;
        }

        &:hover,
        &:focus {
          background-color: #1d3d71;
        }

        .sp-nav__link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:after {
            color: #fff;

            @extend .#{$prefix}icon;
            @include font-size(12px);
            content: map-get($icon-defs, "chevron-right");
          }
        }
      }
    }

    &__link {
      @include font-size(18px);
      display: block;
      padding: $nav--padding;
      transition: $nav--transition;
      text-decoration: none;
      color: #fff;
      outline: none;
      position: relative;

      &:hover,
      &:focus {
        @include bp($nav--breakpoint) {
          color: $nav--hover-colour;
          background: $nav--hover-bg;

          &:after {
            color: $nav--hover-colour;
          }
        }
      }

      &:hover {
        @include bp($nav--breakpoint) {
          padding-bottom: 0.35em;
        }
      }

      &.is-active {
        background: $nav--active-bg;
      }

      @include bp($nav--breakpoint) {
        padding: $nav--padding 0 0.2em;
        margin: 0 $nav--padding;
        color: $nav--text-colour;
        @include font-size(20px);
        border-bottom: 2px solid transparent;
      }

      @include bp(lg) {
        @include font-size(24px);
      }
    }

    &__subnav {
      @extend .#{$prefix}clearfix;
      display: none;
      list-style: none;
      padding: 1em 1.5em;
      background: $blueBiscay;

      @include bp($nav--breakpoint) {
        display: block;
        max-height: 0;
        padding: 0;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin: 0;
        border-bottom: 0px solid transparent;
        background: $nav--subnav-bg;
        transition: all 350ms ease-in-out;
        text-align: center;
      }

      &__item {
        padding: 0;
        text-align: left;

        @include bp($nav--breakpoint) {
          width: 19.5%;
          max-width: 222px;
          display: inline-block;
          padding: 0 1em;
          font-weight: 600;
          vertical-align: top;
        }

        &.is-active {
          font-weight: 700;
        }
      }

      &__link {
        @include font-size(15px);
        text-decoration: none;
        color: #fff;
        line-height: 22px;
        position: relative;
        padding: 0.5em 0 0.5em 20px;
        display: block;

        &:before {
          @extend .#{$prefix}icon;
          @include font-size(12px);
          content: map-get($icon-defs, "chevron-right");
          position: absolute;
          left: 0;
        }

        @include bp($nav--breakpoint) {
          @include font-size(17px);
          text-transform: uppercase;
          color: $blueDenim;

          &:before {
            content: none;
          }
        }

        &:hover,
        &:focus {
          text-decoration: underline;

          // IE11 is weird with text-decoration on pseudo elements.
          // This is pretty much the only way to get it to behave
          &:before {
            text-decoration: underline;
          }

          &:before {
            text-decoration: none;
          }
        }
      }

      .#{$prefix}nav {
        &__subnav {
          @include bp($nav--breakpoint) {
            position: static;
            border: 0;
            padding: 1em 0 0;
            max-height: none;
            overflow: visible;

            .#{$prefix}nav__subnav__item {
              width: auto;
              float: none;
              padding: 7px 0;
              font-weight: 400;
              display: block;

              &.is-active {
                font-weight: 700;
              }
            }

            .#{$prefix}nav__subnav__link {
              color: $blueChatham;
              @include font-size(15px);
              line-height: 22px;
              text-transform: none;
              position: relative;
              display: inline-block;
              padding: 0 0 0 20px;

              &:before {
                font-family: "icons";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: inherit;
                vertical-align: top;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                @include font-size(12px);
                color: $blueChatham;
                content: map-get($icon-defs, "chevron-right");
                position: absolute;
                left: 0;
                top: 0;
                transition: left 200ms ease-in-out;
              }

              &:hover,
              &:focus {
                color: #000;
                text-decoration: underline;

                &:before {
                  color: #000;
                  left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.#{$prefix}nav--side {
  z-index: 10;
  display: none;

  @include bp($nav--breakpoint) {
    display: block;
    overflow: visible;
    width: auto;
    background: none;
    margin: 0.5em 0;
  }

  .#{$prefix}nav {
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      background: $greyGallery;
    }

    &__item {
      display: none;
      font-weight: bold;
      width: 100%;
      margin: 0;
      padding: 0;

      &.is-active {
        display: block;
      }
    }

    &__link {
      @include font-size(20px);
      display: block;
      padding: 15px 20px;
      text-decoration: none;
      color: $blueDenim;
      outline: none;
      position: relative;
      transition: $nav--transition;

      &:hover,
      &:focus {
        background: darken($greyGallery, 4);
        color: $blueBiscay;
      }
    }

    &__subnav {
      list-style: none;
      background: $greyNurse;
      font-weight: 400;
      padding: 0;
      margin: 0;

      &__item {
        position: relative;

        &.is-active {
          font-weight: 700;
          background: $blueDenim;
          margin: -2px 0 0;
          padding: 0.5em 0;

          .#{$prefix}nav__subnav__link {
            color: #fff;
            border-bottom: 0;

            &:after {
              content: map-get($icon-defs, "chevron-down");
              color: #fff;
              top: 25px;
            }

            &:hover,
            &:focus {
              background: $blueTory;

              &:after {
                right: 1.5em;
              }
            }
          }

          .#{$prefix}nav__subnav {
            display: block;
          }
        }

        &:last-child {
          .#{$prefix}nav__subnav__link {
            border-bottom: 0;
          }
        }
      }

      &__link {
        @include font-size(16px);
        display: block;
        text-decoration: none;
        padding: 1em 2em;
        color: $blueChatham;
        line-height: 22px;
        border-bottom: 2px solid $greySilver;
        transition: $nav--transition;

        &:after {
          @extend .#{$prefix}icon;
          @include font-size(12px);
          content: map-get($icon-defs, "chevron-right");
          color: $greySilver;
          position: absolute;
          right: 1.5em;
          top: 17px;
          transition: $nav--transition;
        }

        &:hover,
        &:focus {
          background: lighten($greyNurse, 5);
          color: $blueDenim;

          &:after {
            right: 1em;
          }
        }
      }

      .#{$prefix}nav__subnav {
        display: none;
        font-weight: 400;
        background: $blueDenim;

        .#{$prefix}nav__subnav__item {
          padding: 0;
        }

        .#{$prefix}nav__subnav__link {
          border-bottom: 0;
          padding: 0.6em 1em 0.6em 3em;

          &:after {
            content: none;
          }

          &:hover,
          &:focus {
            background: $blueTory;
          }
        }
      }
    }
  }
}

.#{$prefix}nav--horz {
  margin: 0 -$nav--padding / 2;

  .#{$prefix}nav__button {
    display: none;
  }

  .#{$prefix}nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline;
  }

  .#{$prefix}nav__item {
    display: inline-block;
    line-height: 1;
    height: 0.8em;

    &.is-active {
      font-weight: bold;
    }

    &:last-child {
      border: 0;
    }
  }

  .#{$prefix}nav__link {
    display: block;
    padding: 0 $nav--padding / 2;
  }
}

.#{$prefix}nav--select {
  .custom {
    &.replaced {
      height: 39px;
    }

    &-select {
      @include font-size(13px);

      & > span {
        background: $blueSinbad;
        border: 0;
        color: $blueBiscay;
        line-height: 39px;
        padding: 0 4em 0 3em;

        &:after {
          @include font-size(10px);
          line-height: 41px;
          height: 39px;
          width: 39px;
          background: $blueBiscay;
          color: #fff;
        }
      }

      span span {
        height: 39px;
      }

      // &-container {}
    }
  }
}

// .is-external links used in main navigation
.#{$prefix}nav--main a.is-external {
  &:after {
    @extend .#{$prefix}icon;
    content: map-get($icon-defs, "external");
    display: inline-block;
    padding: 0.04em 0 0 0.5em;
    @include font-size(12px);
  }
}

// .is-external links used in side navigation
.sp-nav--side li.sp-nav__subnav__item .sp-nav__subnav__link.is-external {
  padding-right: 2.3em;

  &:after {
    @extend .#{$prefix}icon;
    content: map-get($icon-defs, "external");
    position: absolute;
    top: 11px;
    @include font-size(12px);
  }
}
